import {
  Box,
  Dialog,
  Divider,
  IconButton,
  SxProps,
  Typography,
  useMediaQuery,
} from '@mui/material';
import { pxToRem, pxTovW } from '../../../commonUtils/resizeUtils';
import { IStyles } from '../../../commonUtils/styleUtils';
import ImageWrapper from '../../elements/ImageWrapper';
import { theme } from '../../../theme/themeProvider';
import { StudentAssessmentTask } from '@protos/learning_management/lms.assessment.student.apis_pb';
import { ReactNode, useState } from 'react';
import { IconWrapper } from '../../elements/IconWrapper/Index';
import {
  firstLetterImage,
  getMediaBasePath,
} from '../../../commonUtils/images';
import { remainingTimeInHoursAndMins } from '../../../commonUtils/homework';
import {
  AssessmentEnum,
  SubmissionType,
  TaskEnum,
  TaskStudentAttemptStatusEnum,
} from '@protos/learning_management/lms.db_pb';
import { formatDateAsDayMonth } from '../../../commonUtils/utilFunctions';
import PrimaryButton from '../../elements/PrimaryButton';
import { ContentListAccordian } from '../ContentListAccordian';
import { AssessmentTask } from '@protos/learning_management/lms.assessment.common.apis_pb';
const styles: IStyles = {
  root: {
    height: { xs: pxToRem(148), md: pxTovW(280), lg: pxTovW(189) },
    width: { xs: '100%', md: pxTovW(690), lg: pxTovW(464) },
    // maxWidth: { xs: pxToRem(360), md: pxTovW(590), lg: pxTovW(464) },
    borderRadius: { xs: pxToRem(10), md: pxTovW(15) },
    backgroundColor: '#FFFFFF',
    border: '1px solid #E0DFDE',
    padding: {
      xs: pxToRem(8),
      md: `${pxTovW(18)} ${pxTovW(15)}`,
      lg: `${pxTovW(13)} ${pxTovW(12)}`,
    },
    display: 'flex',
    alignItems: 'center',
    gap: { xs: pxToRem(15), md: pxTovW(15), lg: pxTovW(15) },
    boxSizing: 'border-box',
    boxShadow: `0px 0px ${pxToRem(11)} #E7E7E7D9`,
    cursor: 'pointer',
    // marginX: 'auto',
  },
  imageBox: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-around',
    height: { xs: '100%', md: '100%', lg: pxTovW(157) },
    width: { xs: pxToRem(103), md: pxTovW(221), lg: pxTovW(157) },
    minWidth: { xs: pxToRem(103), md: pxTovW(221), lg: pxTovW(157) },
    boxSizing: 'border-box',
    borderRadius: { xs: pxToRem(10) },
    backgroundColor: '#E0DFDE',
    position: 'relative',
  },
};

interface IconDetails {
  iconName: string;
  text: string;
  label: string;
}

interface IProps {
  image?: string;
  homeworkItem: StudentAssessmentTask;
  currentTabValue?: string;
  rootStyle?: SxProps;
  // variant: keyof IVariants;
  iconDetails?: IconDetails[];
  onCardClick?: () => void;
  chapterListPopupHandler?: (assessment: StudentAssessmentTask) => void;
  // submissionType?: SubmissionType;
  displayScore?: boolean;
}

export const AssessInfoCard = ({
  image,
  homeworkItem,
  currentTabValue,
  rootStyle,
  iconDetails,
  onCardClick,
  chapterListPopupHandler,
  displayScore,
}: IProps) => {
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const isIpad = useMediaQuery(theme.breakpoints.down('lg'));
  const isIpadOnly = !isMobile && isIpad;

  if (currentTabValue === undefined) currentTabValue = 'Active';

  // let rootSx = { ...styles.root };
  // if (rootStyle) rootSx = { ...rootSx, ...rootStyle };

  return (
    <Box sx={styles.root} onClick={onCardClick}>
      <Box sx={styles.imageBox}>
        <ImageWrapper
          name="chapter1"
          type="png"
          parentFolder="tempAssets"
          styles={{
            ...styles.image,
            height: '100%',
            objectFit: 'cover',
          }}
          path={getMediaBasePath(
            homeworkItem?.assessmentPosterImgUrl,
            'processedMediaBucket'
          )}
        />
      </Box>
      <Box
        sx={{
          height: '100%',
          display: 'flex',
          flexDirection: 'column',
          flexGrow: 1,
          justifyContent: 'space-between',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            width: '100%',
            gap: { xs: pxToRem(3), md: pxTovW(4) },
          }}
        >
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              height: { xs: pxToRem(17), md: pxTovW(28) },
              gap: { xs: pxToRem(10), md: pxTovW(16) },
              marginTop: { xs: pxToRem(2), md: pxTovW(10), lg: pxTovW(5) },
              marginBottom: { xs: pxToRem(1), md: pxTovW(5) },
            }}
          >
            {homeworkItem?.taskType ===
            TaskEnum.TASK_HOMEWORK_TEACHER_CUSTOM ? (
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  // marginTop: { xs: pxToRem(5), md: pxTovW(10), lg: pxTovW(5) },
                  background: '#F8C807',
                  padding: {
                    xs: `${pxToRem(3)} ${pxToRem(7)} ${pxToRem(4)}`,
                    md: `${pxTovW(5)} ${pxTovW(14)} ${pxTovW(6)}`,
                  },
                  borderRadius: {
                    xs: pxToRem(10),
                    md: pxTovW(20),
                    lg: pxTovW(16),
                  },
                }}
              >
                <Typography
                  variant={isMobile ? 'smallestText' : 'subText'}
                  fontWeight={700}
                >
                  Custom HW
                </Typography>
              </Box>
            ) : homeworkItem.assessmentType ===
              AssessmentEnum.ASSESSMENT_FORMATIVE_ASSESSMENT ? (
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  // marginTop: { xs: pxToRem(5), md: pxTovW(10), lg: pxTovW(5) },
                  background: '#007CDC',
                  padding: {
                    xs: `${pxToRem(3)} ${pxToRem(8)} ${pxToRem(4)}`,
                    md: `${pxTovW(5)} ${pxTovW(9)} ${pxTovW(6)}`,
                  },
                  borderRadius: {
                    xs: pxToRem(10),
                    md: pxTovW(20),
                    lg: pxTovW(15),
                  },
                }}
              >
                <Typography
                  variant={isMobile ? 'smallestText' : 'subText'}
                  color={'common.white'}
                  fontWeight={700}
                >
                  Formative
                </Typography>
              </Box>
            ) : homeworkItem.assessmentType ===
              AssessmentEnum.ASSESSMENT_DIAGNOSTICS ? (
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  // marginTop: { xs: pxToRem(5), md: pxTovW(10), lg: pxTovW(5) },
                  background: '#F87606',
                  padding: {
                    xs: `${pxToRem(3)} ${pxToRem(8)} ${pxToRem(4)}`,
                    md: `${pxTovW(5)} ${pxTovW(9)} ${pxTovW(6)}`,
                  },
                  borderRadius: {
                    xs: pxToRem(10),
                    md: pxTovW(20),
                    lg: pxTovW(15),
                  },
                }}
              >
                <Typography
                  variant={isMobile ? 'smallestText' : 'subText'}
                  color={'common.white'}
                  fontWeight={700}
                >
                  Diagnostic
                </Typography>
              </Box>
            ) : (
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  // marginTop: { xs: pxToRem(5), md: pxTovW(10), lg: pxTovW(5) },
                  background: '#0AA34F',
                  padding: {
                    xs: `${pxToRem(3)} ${pxToRem(7)} ${pxToRem(4)}`,
                    md: `${pxTovW(5)} ${pxTovW(10)} ${pxTovW(6)}`,
                  },
                  borderRadius: {
                    xs: pxToRem(10),
                    md: pxTovW(20),
                    lg: pxTovW(15),
                  },
                }}
              >
                <Typography
                  variant={isMobile ? 'smallestText' : 'subText'}
                  color={'common.white'}
                  fontWeight={700}
                >
                  Homework
                </Typography>
              </Box>
            )}
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                gap: { xs: pxToRem(5), md: pxTovW(5) },
              }}
            >
              <ImageWrapper
                name="geneo-blue"
                type="png"
                parentFolder="icons"
                styles={{
                  width: { xs: pxToRem(17), md: pxTovW(34), lg: pxTovW(24) },
                  height: { xs: pxToRem(17), md: pxTovW(34), lg: pxTovW(24) },
                  borderRadius: '50%',
                }}
                path={
                  getMediaBasePath(homeworkItem.teacherProfileImageUrl) ||
                  firstLetterImage(homeworkItem.teacherName)
                }
              />

              <Typography
                variant={isMobile ? 'smallestText' : 'smallText'}
                fontWeight="regular"
              >
                {homeworkItem?.teacherName}
              </Typography>
            </Box>
          </Box>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
            }}
          >
            <Typography
              variant={isMobile ? 'smallestText' : 'subText'}
              fontWeight={700}
              color="primary.main"
            >
              {homeworkItem?.subject}
            </Typography>
            {homeworkItem?.assessmentType ===
              AssessmentEnum.ASSESSMENT_FORMATIVE_ASSESSMENT &&
              homeworkItem.taskMetaInfo?.chapterInfo?.chapterTopicsInfo && (
                <Box
                  sx={{
                    display: 'flex',
                    width: 'max-content',
                    height: { xs: pxToRem(12), md: pxTovW(15) },
                    // backgroundColor: 'red',
                    gap: { xs: pxToRem(3), md: pxTovW(8), lg: pxTovW(5) },
                  }}
                  onClick={(ev?: React.MouseEvent) => {
                    ev?.stopPropagation();
                    chapterListPopupHandler &&
                      chapterListPopupHandler(homeworkItem);
                  }}
                >
                  {homeworkItem.taskMetaInfo?.chapterInfo?.chapterTopicsInfo
                    .length > 1 && (
                    <Typography
                      variant={isMobile ? 'smallestText' : 'subText'}
                      fontWeight="bold"
                    >
                      {`+${
                        homeworkItem.taskMetaInfo?.chapterInfo
                          ?.chapterTopicsInfo.length - 1
                      } more`}
                    </Typography>
                  )}

                  <Box
                    sx={{
                      display: 'flex',

                      justifyContent: 'center',

                      alignItems: 'center',

                      width: { xs: pxToRem(12), md: pxTovW(15) },

                      height: { xs: pxToRem(12), md: pxTovW(15) },

                      background: 'black',

                      borderRadius: {
                        xs: pxToRem(10),

                        md: pxTovW(20),

                        lg: pxTovW(15),
                      },
                    }}
                  >
                    <Typography variant="smallestText" color={'common.white'}>
                      i
                    </Typography>
                  </Box>
                </Box>
              )}
          </Box>

          <Typography
            variant={isMobile ? 'h4' : 'h5'}
            fontWeight={700}
            sx={{
              display: '-webkit-box',
              WebkitLineClamp: 1,
              WebkitBoxOrient: 'vertical',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              wordWrap: 'break-word',
              maxWidth: {
                xs: pxToRem(190),
                md: pxTovW(320),
                lg: pxTovW(220),
              },
            }}
          >
            {homeworkItem?.moduleName}
          </Typography>

          <Box
            sx={{
              width: '100%',
              display: 'flex',
              justifyContent: 'space-between',
            }}
          >
            <Typography
              variant={isMobile ? 'smallestText' : 'subText'}
              fontWeight={500}
              sx={{
                display: '-webkit-box',
                WebkitLineClamp: 1,
                WebkitBoxOrient: 'vertical',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                wordWrap: 'break-word',
                whiteSpace: 'nowrap',
                marginTop: { xs: pxToRem(4), md: pxTovW(7) },
                maxWidth: {
                  xs: pxToRem(100),
                  md: pxTovW(180),
                  lg: pxTovW(160),
                },
              }}
            >
              {homeworkItem?.assessmentTitle}
            </Typography>
            <Box
              sx={{
                // width: '100%',
                display: 'flex',
                justifyContent: 'space-between',
              }}
            >
              {/* {iconDetails.map((elem, index) => ( */}
              <IconTextBox
                // key={index}
                iconName={currentTabValue === 'Active' ? 'clock' : 'calender'}
                text={
                  currentTabValue === 'Active'
                    ? remainingTimeInHoursAndMins(homeworkItem.endDate)
                    : formatDateAsDayMonth(homeworkItem.endDate)
                }
                label={currentTabValue === 'Active' ? 'Remaining' : 'Deadline'}
              />
              {/* ))} */}
            </Box>
          </Box>
        </Box>
        <Divider
          sx={{
            borderColor: '1px solid #E0E0E0',
          }}
        />
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
          }}
        >
          {/* {homeworkItem?.assessmentType === AssessmentEnum.ASSESSMENT_HOMEWORK && */}
          <Box>
            {homeworkItem?.taskType ===
            TaskEnum.TASK_HOMEWORK_TEACHER_CUSTOM ? (
              <Box
                sx={{
                  display: 'flex',
                  gap: { xs: pxToRem(4), md: pxTovW(4) },
                  alignItems: 'center',
                }}
              >
                <IconWrapper
                  name={
                    homeworkItem?.submissionType ===
                    SubmissionType.SUBMISSION_ONLINE
                      ? 'digital-submission'
                      : 'physical-submission'
                  }
                  size={isIpadOnly ? 'large' : 'md'}
                  parentFolder="icons"
                  type="png"
                />
              </Box>
            ) : (
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                }}
              >
                <IconTextBox
                  iconName="questions"
                  text={homeworkItem?.noOfQuestions.toString()}
                  label="Questions"
                />
              </Box>
            )}
          </Box>
          {homeworkItem?.studentAttemptStatus ===
          TaskStudentAttemptStatusEnum.TASK_STUDENT_STATUS_COMPLETED ? (
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                background: '#E1FFEE',
                border: '1px solid #0AA34F',
                padding: {
                  xs: `${pxToRem(4)} ${pxToRem(6)}`,
                  md: `${pxTovW(4)} ${pxTovW(7)} ${pxTovW(7)}`,
                },
                borderRadius: {
                  xs: pxToRem(10),
                  md: pxTovW(21),
                  lg: pxTovW(16),
                },
              }}
            >
              {homeworkItem?.taskType ===
                TaskEnum.TASK_HOMEWORK_TEACHER_CUSTOM &&
              !homeworkItem.maxMarks ? (
                <Typography variant="smallText" color={'secondary.main'}>
                  {homeworkItem?.submissionType ===
                  SubmissionType.SUBMISSION_ONLINE
                    ? 'Completed'
                    : 'Submitted'}
                </Typography>
              ) : (
                <Typography variant="smallText" color={'secondary.main'}>
                  {Math.round(homeworkItem?.scorePercent) > 0
                    ? `Completed with ${Math.round(
                        homeworkItem?.scorePercent
                      )}%`
                    : 'Completed'}
                </Typography>
              )}
            </Box>
          ) : homeworkItem?.studentAttemptStatus ===
            TaskStudentAttemptStatusEnum.TASK_STUDENT_STATUS_PENDING ? (
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                background: '#FFE1E1',
                border: '1px solid #A31C0A',
                padding: {
                  xs: `${pxToRem(1)} ${pxToRem(10)} ${pxToRem(2)}`,
                  md: `${pxTovW(4)} ${pxTovW(20)} ${pxTovW(7)}`,
                },
                borderRadius: {
                  xs: pxToRem(15),
                  md: pxTovW(21),
                  lg: pxTovW(16),
                },
              }}
            >
              <Typography variant="smallText" color={'#A30A0A'}>
                {currentTabValue === 'Active' ? 'Pending ' : 'Not Submitted'}
              </Typography>
            </Box>
          ) : homeworkItem?.studentAttemptStatus ===
            TaskStudentAttemptStatusEnum.TASK_STUDENT_STATUS_TIMED_OUT ? (
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                background: '#E1FFEE',
                border: '1px solid #0AA34F',
                padding:
                  homeworkItem.scorePercent !== 0
                    ? {
                        xs: `${pxToRem(4)} ${pxToRem(6)}`,
                        md: `${pxTovW(4)} ${pxTovW(7)} ${pxTovW(7)}`,
                      }
                    : {
                        xs: `${pxToRem(4)} ${pxToRem(6)}`,
                        md: `${pxTovW(4)} ${pxTovW(12)} ${pxTovW(7)}`,
                      },

                borderRadius: {
                  xs: pxToRem(10),
                  md: pxTovW(21),
                  lg: pxTovW(16),
                },
              }}
            >
              <Typography variant="smallText" color={'secondary.main'}>
                {Math.round(homeworkItem?.scorePercent) > 0
                  ? `Completed with ${Math.round(homeworkItem?.scorePercent)}%`
                  : 'Completed'}
              </Typography>
            </Box>
          ) : (
            homeworkItem?.studentAttemptStatus ===
              TaskStudentAttemptStatusEnum.TASK_STUDENT_STATUS_IN_PROGRESS && (
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  background:
                    currentTabValue === 'Active' ? '#FFF8DA' : '#FFE1E1',
                  border:
                    currentTabValue === 'Active'
                      ? '1px solid #F8C807'
                      : '1px solid #A31C0A',
                  padding:
                    currentTabValue === 'Active'
                      ? {
                          xs: `${pxToRem(2)} ${pxToRem(6)}`,
                          md: `${pxTovW(4)} ${pxTovW(7)} ${pxTovW(7)}`,
                        }
                      : {
                          xs: `0 ${pxToRem(10)} ${pxToRem(1)}`,
                          md: `${pxTovW(4)} ${pxTovW(15)} ${pxTovW(7)}`,
                          lg: `${pxTovW(1)} ${pxTovW(10)} ${pxTovW(3)}`,
                        },
                  borderRadius:
                    currentTabValue === 'Active'
                      ? {
                          xs: pxToRem(10),
                          md: pxTovW(21),
                          lg: pxTovW(16),
                        }
                      : {
                          xs: pxToRem(15),
                          md: pxTovW(24),
                          lg: pxTovW(16),
                        },
                }}
              >
                <Typography
                  variant="smallText"
                  color={currentTabValue === 'Active' ? '#F8C807' : '#A31C0A'}
                >
                  {currentTabValue === 'Active' ? 'In Progress' : 'Exited'}
                  {/* {`${Math.round(homeworkItem?.scorePercent)}%`} */}
                </Typography>
              </Box>
            )
          )}
        </Box>
      </Box>
    </Box>
  );
};

interface IBox {
  iconName: string;
  text: string;
  label: string;
}
const IconTextBox = ({ iconName, text, label }: IBox) => {
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const isIpad = useMediaQuery(theme.breakpoints.down('lg'));
  const isIpadOnly = !isMobile && isIpad;
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: { xs: pxToRem(3), md: pxTovW(3) },
      }}
    >
      <Box
        sx={{
          display: 'flex',
          gap: { xs: pxToRem(4), md: pxTovW(4) },
          alignItems: 'center',
        }}
      >
        <IconWrapper
          name={iconName}
          size={isIpadOnly ? 'md' : 'small'}
          parentFolder="icons"
          type="png"
        />

        <Typography variant={'smallText'}>{text}</Typography>
      </Box>

      <Typography variant={'smallestText'}>{label}</Typography>
    </Box>
  );
};
