import { Outlet, ScrollRestoration, useLocation } from 'react-router-dom';

import {
  ContactPopup,
  Sidebar,
  Toast,
  deserify,
  pxToRem,
  pxTovW,
} from '@geneo2-web/shared-ui';
import { Box, useMediaQuery, useTheme } from '@mui/material';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { interactionEvent } from '../../pages/Auth/Login/login_logout.events';
import { setContactPopupOpen } from '../../pages/Auth/reducer/auth.slice';
import { useAppSelector } from '../../reduxStore/reduxHooks';
import { useSideBarOptions } from '../../utils/sidebarOptions';
import Header from '../Header';
import { setToastInfo } from '../../pages/Home/reducer/homeDashboard.slice';
import { useDownloadContext } from '../../app/Context/DownloadContextProviderV2';

interface AppLayoutProps {
  hideHeader?: boolean;
}

export const AppLayout: React.FC<AppLayoutProps> = (props) => {
  const { hideHeader } = props;
  const location = useLocation();
  const pathSegments = location.pathname.split('/');
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const [sidebarDisplay, setSidebarDisplay] = useState(false);
  const options = useSideBarOptions();
  const { contactPopupOpen } = deserify(useAppSelector((state) => state.auth));
  const { toast_info } = deserify(useAppSelector((state) => state.home));
  const { isOffline } = useDownloadContext();
  const dispatch = useDispatch();
  const closeToastHandler = () => {
    dispatch(setToastInfo({ label: '', variant: 'success', open: false }));
  };

  return (
    <>
      <Toast
        variant={toast_info?.variant}
        open={toast_info?.open}
        label={toast_info?.label}
        onClose={closeToastHandler}
      />
      {isMobile && (
        <Sidebar
          open={sidebarDisplay}
          handleClose={() => setSidebarDisplay(false)}
          options={options}
        />
      )}
      {!hideHeader && (
        <Header
          sidebarDisplay={sidebarDisplay}
          setSidebarDisplay={setSidebarDisplay}
        />
      )}
      <ContactPopup
        open={contactPopupOpen}
        setOpen={async (val: boolean) => {
          dispatch(setContactPopupOpen(val));
          await interactionEvent({
            url: '',
            context: 'contact',
            name: 'contact_us_popup_close',
            pathSegments: pathSegments,
            isOffline: isOffline,
          });
        }}
      />
      {/* {!hideHeader && <Header />} */}
      <Box
        sx={{
          paddingTop: { xs: pxToRem(60), md: pxTovW(100) },
          height: {
            xs: `calc(100% - ${pxToRem(60)})`,
            md: `calc(100% - ${pxTovW(100)})`,
          },
        }}
      >
        <Outlet />
        <ScrollRestoration />
      </Box>
    </>
  );
};

export default AppLayout;
