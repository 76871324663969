import { Box, Divider, Typography, useMediaQuery } from '@mui/material';

import { Timestamp } from '@bufbuild/protobuf';
import {
  HomeworkTimer,
  IStyles,
  ImageWrapper,
  InfoBarWithDivider,
  InstructionsPanel,
  InstructionsPanelWithArray,
  Loader,
  NoContentCard,
  PrimaryButton,
  deserify,
  pxToRem,
  pxTovW,
  shuffleListWithSeed,
  theme,
  useCommonServiceClientContext,
} from '@geneo2-web/shared-ui';
import {
  AssessmentSessionModeEnum,
  AssignmentConfigEnum,
  HomeworkSessionModeEnum,
  TaskEnum,
  TaskStudentAttemptStatusEnum,
} from '@protos/learning_management/lms.db_pb';
import { HomeworkContent } from '@protos/learning_management/lms.hw.common.apis_pb';
import { ProfileRolesEnum } from '@protos/user_management/ums.db_pb';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { useGlobalContext } from '../../../app/Context/GlobalContextProvider';
import { useAppSelector } from '../../../reduxStore/reduxHooks';
import {
  HOME,
  HOMEWORK_ACTIVE,
} from '../../../routeHandling/RoutesNomenclature';
import { getLastAttemptedResponse } from '../../../utils/homework';
import { interactionEvent } from '../../Auth/Login/login_logout.events';
import { setToastInfo } from '../../Home/reducer/homeDashboard.slice';
import {
  setActiveHomeworSessionId,
  // setActiveHomeworSessionId,
  setActiveHomeworkContent,
  setActiveHomeworkStudentResponse,
  setHomeworkEndPath,
  setSelectedHomeworkData,
} from '../reducer/homework.slice';
import { useDownloadContext } from '../../../app/Context/DownloadContextProviderV2';
import { checkFeatureData } from '../utils_homework/homeworkUtil';
import { AssessmentContent } from '@protos/learning_management/lms.assessment.common.apis_pb';

const styles: IStyles = {
  root: {
    // border: '1px solid black',
    boxSizing: 'border-box',
    p: {
      xs: `${pxToRem(20)} ${pxToRem(0)}`,
      md: `${pxTovW(27)} ${pxTovW(410)} ${pxTovW(92)} ${pxTovW(240)}`,
    },
    bgcolor: 'neutral.lightOrange',
    minHeight: '90vh',
  },

  headingTimeBox: {
    // textAlign: { xs: 'center', md: 'unset' },
    textAlign: 'center',
    // display: 'flex',
    // flexDirection: 'column',
    // alignItems: 'center',
  },

  iconTextWrapperBox: {
    display: 'flex',
    alignItems: 'center',
    gap: { xs: pxToRem(10), md: pxTovW(8) },
    // justifyContent: { xs: 'center', md: 'unset' },
    justifyContent: 'space-between',
    bgcolor: '#FFFFFF',
    boxShadow: '0px 0px 20px #00000014',
    borderRadius: '5px',
    paddingX: { xs: pxToRem(10), md: pxTovW(20) },
    height: { xs: pxToRem(50), md: pxTovW(150), lg: pxTovW(100) },
    width: { xs: pxToRem(300), md: pxTovW(800), lg: pxTovW(500) },
  },
  iconTextBox: {
    display: 'flex',
    flexDirection: 'row',
    gap: { xs: pxToRem(10), md: pxTovW(10) },
  },
  timeClock: {
    width: { xs: pxToRem(10), md: pxTovW(55), lg: pxTovW(33) },
    height: { xs: pxToRem(10), md: pxTovW(55), lg: pxTovW(33) },
  },

  bottomContainer: {
    // textAlign: 'center',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: { xs: pxToRem(10), md: pxTovW(40) },
  },
  startInfoBox: {
    // textAlign: 'center',
    mb: { xs: pxToRem(10), md: pxTovW(20) },
    display: 'flex',
    justifyContent: 'center',
    gap: { xs: pxToRem(7), md: pxTovW(7) },
  },
  dino: {
    width: { xs: pxToRem(109), md: pxTovW(313) },
    height: { xs: pxToRem(108), md: pxTovW(257) },
    objectFit: { xs: 'cover', lg: 'contain' },
  },

  startInfoIcon: {
    width: { xs: pxToRem(10), md: pxTovW(20) },
    height: { xs: pxToRem(10), md: pxTovW(20) },
  },

  instructionBox: {
    width: { xs: '100%' },
    paddingX: { xs: pxToRem(20), md: pxTovW(20) },
    paddingY: { xs: pxToRem(20), md: pxTovW(20) },
    // boarder: '1px solid #CCE6FE',
    boxSizing: 'border-box',
  },
  instructionCard: {
    display: 'flex',
    flexDirection: 'column',
    padding: { xs: pxToRem(14), lg: pxTovW(10) },
    boxSizing: 'border-box',
    paddingBottom: { xs: pxToRem(14), md: pxTovW(47) },
    borderRadius: { xs: pxToRem(10), md: pxTovW(15) },
    width: { xs: '100%' },
    backgroundColor: '#F6FBFF',
    border: '1px solid #CCE6FE',
    gap: { xs: pxToRem(20), md: pxTovW(16) },
    marginBottom: { xs: pxToRem(60) },
    // backgroundColor: 'blue',
  },
  textBox: {
    display: 'flex',
    // alignItems: 'center',
    width: '100%',
    paddingLeft: { md: pxTovW(10) },
    paddingRight: { md: pxTovW(10) },
    gap: { xs: pxToRem(6.61), md: pxTovW(6.61) },
    // paddingTop: '20px',
    alignItems: 'flex-start',
  },
};

interface IProps {
  heading?: string;
  time?: string;
  startInfo?: string;
}
export const HwStartCover = ({ heading, time, startInfo }: IProps) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { isIpadOnly } = useGlobalContext();
  const { isOffline } = useDownloadContext();
  const homework_id =
    new URLSearchParams(location.search).get('homeworkId') || undefined;
  const { userInfo } = deserify(useAppSelector((state) => state.auth));
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const isIpad = useMediaQuery(theme.breakpoints.down('lg'));
  const studentId = deserify(
    useAppSelector((state) => state.auth.userInfo?.studentProfileId)
  );
  const {
    active_homework_content,
    active_homework_student_response,
    active_homework_session_id,
  } = deserify(useAppSelector((state) => state.homework));
  const activeHomeworkContent =
    active_homework_content && homework_id
      ? active_homework_content[homework_id]
      : undefined;
  const activeHomeworkStudentResponse =
    active_homework_student_response && homework_id
      ? active_homework_student_response[homework_id]
      : undefined;
  console.log({ activeHomeworkStudentResponse });
  const dispatch = useDispatch();
  const [loading, setLoading] = useState<'loading' | 'completed' | 'error'>(
    'completed'
  );
  const homework_end_path = useAppSelector(
    (state) => state.homework.homework_end_path
  );
  const { setSelectedFunction } = useGlobalContext();

  const totalTasks = activeHomeworkContent?.assessmentContent.length || 0;

  const assessmentStatusTimedOut =
    activeHomeworkStudentResponse?.assessmentAttemptStatus ===
    TaskStudentAttemptStatusEnum.TASK_STUDENT_STATUS_TIMED_OUT;
  const completedTasks = assessmentStatusTimedOut
    ? 0
    : activeHomeworkStudentResponse?.responses?.reduce((a, b) => {
        return (
          a +
          (b.responses && getLastAttemptedResponse(b.responses)?.answer?.length
            ? 1
            : 0)
        );
      }, 0) || 0;
  const {
    LmsHomeworkCommonAPIServiceV1ClientWithStatusCodeHandler,
    LmsAssessmentStudentApiServiceV1ClientWithStatusCodeHandler,
    LmsAssessmentCommonAPIServiceV1ClientWithStatusCodeHandler,
  } = useCommonServiceClientContext();

  const startButtonHandler = async () => {
    // console.log('startButtonHandler');
    if (
      new Timestamp(
        activeHomeworkContent?.assessment?.assessmentTargetDate
      ).toDate() < new Date(Date.now())
    ) {
      dispatch(
        setToastInfo({
          label: `Apologies, Deadline for ${
            completedTasks === 0 ||
            !completedTasks ||
            completedTasks < totalTasks
              ? ''
              : 're'
          } attempt has passed.`,
          variant: 'error',
          open: true,
        })
      );
      return;
    }

    if (!homework_id || !studentId) {
      return;
    }
    const sessionMode =
      completedTasks === 0 || !completedTasks
        ? AssessmentSessionModeEnum.ASSESSMENT_SESSION_MODE_FIRST_ATTEMPT
        : completedTasks >= totalTasks
        ? AssessmentSessionModeEnum.ASSESSMENT_SESSION_MODE_REATTEMPT
        : AssessmentSessionModeEnum.ASSESSMENT_SESSION_MODE_RESUME;
    try {
      const hwSession =
        await LmsAssessmentStudentApiServiceV1ClientWithStatusCodeHandler.createStudentAssessmentSession(
          {
            studentId,
            assessmentId: Number(homework_id),
            sessionMode: sessionMode,
            sessionStatus:
              TaskStudentAttemptStatusEnum.TASK_STUDENT_STATUS_IN_PROGRESS,
          }
        );
      if (
        sessionMode ===
        AssessmentSessionModeEnum.ASSESSMENT_SESSION_MODE_REATTEMPT
      ) {
        dispatch(
          setActiveHomeworkStudentResponse({
            homeworkId: Number(homework_id),
            response: undefined,
          })
        );
      }
      if (assessmentStatusTimedOut) {
        dispatch(
          setActiveHomeworkStudentResponse({
            homeworkId: Number(homework_id),
            response: undefined,
          })
        );
      }

      // Only shuffle questions and dispatch if hwSession.attemptNumber is available
      if (
        hwSession?.attemptNumber &&
        checkFeatureData({
          assignmentConfigInfo:
            activeHomeworkContent?.assessment?.assignmentInfo,
          userInfo: userInfo,
        }).shuffling
      ) {
        const activeHomeworkContent =
          active_homework_content && homework_id
            ? active_homework_content[homework_id]
            : undefined;
        const randomQuestion = activeHomeworkContent?.questionsSequenceInfo
          ?.taskQuestionInfo
          ? shuffleListWithSeed(
              activeHomeworkContent?.questionsSequenceInfo.taskQuestionInfo,
              Number(studentId) * hwSession.attemptNumber
            )
          : [];

        const sequenceUpdate = randomQuestion.map((e, i) => ({
          ...e,
          sequence: i + 1,
        }));

        const updatedHomeworkContent = {
          ...activeHomeworkContent,
          questionsSequenceInfo: {
            ...activeHomeworkContent?.questionsSequenceInfo,
            taskQuestionInfo: sequenceUpdate,
          },
        };
        dispatch(
          setActiveHomeworkContent({
            homeworkId: Number(homework_id),
            homeworkContent: updatedHomeworkContent as AssessmentContent,
          })
        );
      } else {
        console.log(
          'hwSession.attemptNumber is missing, skipping randomization and dispatch.'
        );
      }
      if (hwSession.sessionId) {
        dispatch(setActiveHomeworSessionId(hwSession.sessionId));
      } else {
        dispatch(
          setToastInfo({
            label: 'session id is missing',
            variant: 'error',
            open: true,
          })
        );
        return;
      }
      await interactionEvent({
        url: 'Student_homework-start-cover',
        context: 'Homework',
        name:
          completedTasks === 0
            ? "LET'S START"
            : completedTasks >= totalTasks
            ? 'Re-attempt'
            : 'RESUME',
        homeworkSesssionId: hwSession.sessionId?.toString(),
        isOffline: isOffline,
      });
      navigate(
        `${HOMEWORK_ACTIVE}?homeworkId=${homework_id}&sessionMode=${sessionMode}`
      );
    } catch (err: any) {
      console.log('err:2', err.message);
      dispatch(
        setToastInfo({
          label: `${
            err?.message
              ? err.message.replace(/.*\]\s*/, '').trim()
              : 'Homework Start Failed'
          }`,
          variant: 'error',
          open: true,
        })
      );
    }
  };

  const onBackClick = () => {
    navigate(homework_end_path ? homework_end_path : HOME);
    dispatch(setHomeworkEndPath(undefined));
    dispatch(setActiveHomeworSessionId(undefined));
  };

  useEffect(() => {
    fetchHomeworkData();
    setSelectedFunction(() => onBackClick);
    return () => {
      setSelectedFunction(null);
    };
  }, []);

  const fetchHomeworkData = async () => {
    if (!studentId || !homework_id || isNaN(Number(homework_id))) {
      return;
    }
    try {
      setLoading('loading');
      const homeworkContent =
        await LmsAssessmentCommonAPIServiceV1ClientWithStatusCodeHandler.fetchAssessmentContent(
          {
            personId: studentId,
            personType: ProfileRolesEnum.PROFILE_ROLE_STUDENT,
            assessmentId: Number(homework_id),
          }
        );
      const homeworkStudentResponse =
        await LmsAssessmentStudentApiServiceV1ClientWithStatusCodeHandler.fetchStudentAssessmentResponse(
          {
            studentId: studentId,
            assessmentId: Number(homework_id),
          }
        );
      dispatch(
        setActiveHomeworkContent({
          homeworkId: Number(homework_id),
          homeworkContent: homeworkContent.data,
        })
      );
      dispatch(
        setActiveHomeworkStudentResponse({
          homeworkId: Number(homework_id),
          response: homeworkStudentResponse.data,
        })
      );
      dispatch(
        setSelectedHomeworkData({
          homeworkId: homeworkContent?.data?.questionsSequenceInfo?.homeworkId,
          questionIds:
            homeworkContent?.data?.questionsSequenceInfo?.questionIds,
          resumeCheck: completedTasks > 0 && completedTasks < totalTasks,
          reattemptedCheck: completedTasks >= totalTasks,
          SubmissionType: homeworkContent?.data?.assessment?.submissionType,
          elapsedTime:
            completedTasks !== totalTasks
              ? homeworkStudentResponse.data?.attemptSpentTimeInSecs
              : 0,
        })
      );

      setLoading('completed');
    } catch (err) {
      console.error(err);
      setLoading('error');
      dispatch(
        setActiveHomeworkContent({
          homeworkId: Number(homework_id),
          homeworkContent: undefined,
        })
      );
      dispatch(
        setActiveHomeworkStudentResponse({
          homeworkId: Number(homework_id),
          response: undefined,
        })
      );
    }
  };

  const renderInstructionPanel = () => {
    const homeworkInstructions =
      activeHomeworkContent?.assessment?.assessmentInstructions;

    if (!homeworkInstructions || !Array.isArray(homeworkInstructions))
      return null; // Handle no instructions gracefully

    if (homeworkInstructions.length === 1) {
      const singleInstruction = homeworkInstructions[0];
      const isHtmlString = /<\/?[a-z][\s\S]*>/i.test(singleInstruction);

      if (isHtmlString) {
        return (
          <InstructionsPanel
            customSx={{
              display: 'flex',
              flexGrow: 1,
              width: { xs: pxToRem(348), md: pxTovW(900), lg: pxTovW(590) },
              gap: { xs: pxToRem(20), md: pxTovW(20) },
            }}
            title="Instruction:"
            Katexvalue={singleInstruction}
          />
        );
      } else {
        return (
          <InstructionsPanelWithArray
            customSx={{
              display: 'flex',
              flexGrow: 1,
              width: { xs: pxToRem(348), md: pxTovW(900), lg: pxTovW(590) },
              gap: { xs: pxToRem(40), md: pxTovW(20) },
            }}
            title="Instruction:"
            stringArrayValue={[singleInstruction]} // Pass as an array
          />
        );
      }
    } else {
      return (
        <InstructionsPanelWithArray
          customSx={{
            display: 'flex',
            flexGrow: 1,
            width: { xs: pxToRem(348), md: pxTovW(900), lg: pxTovW(590) },
          }}
          title="Instruction:"
          stringArrayValue={homeworkInstructions}
        />
      );
    }
  };

  const isReattempt = activeHomeworkContent?.assessment?.assignmentInfo.find(
    (e) => e.configName === AssignmentConfigEnum.ASSIGNMENT_CONFIG_REATTEMPT
  );

  const infoItems = [
    {
      iconName: 'timerofhwstart',
      value: activeHomeworkContent?.assessment?.estimatedTimeInMin || 0,
      label: 'Minutes',
    },
    {
      iconName: 'noofquestion',
      value: totalTasks,
      label: 'No. Questions',
    },
    {
      iconName: 'reattempts',
      value: isReattempt?.isEnabled ? 'Yes' : 'No',
      label: 'Reattempt Allowed',
    },
  ];

  return loading === 'loading' ? (
    <Loader />
  ) : loading === 'error' ? (
    <NoContentCard variant="error" icon="error" text="Error Occured" />
  ) : (
    <Box sx={styles.root}>
      <Box sx={styles.headingTimeBox}>
        <Typography
          variant="h1"
          sx={{
            px: { xs: pxToRem(20), md: 0 },
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
            WebkitLineClamp: 1,
            WebkitBoxOrient: 'vertical',
            overflow: 'hidden',
            mt: { xs: pxToRem(5), md: pxTovW(10) },
          }}
        >
          {activeHomeworkContent?.assessment?.assessmentTitle || ''}
        </Typography>

        {activeHomeworkContent?.assessment?.taskType !==
          TaskEnum.TASK_HOMEWORK_TEACHER_CUSTOM && (
          <Box
            sx={{
              mt: { xs: pxToRem(15), md: pxTovW(30) },
            }}
          >
            <InfoBarWithDivider items={infoItems} />
          </Box>
        )}
      </Box>

      <Box sx={styles.bottomContainer}>
        <Box
          sx={{
            display: 'flex',
            flexDirection: {
              xs: 'column',
              md: 'row',
            },
            alignItems: 'center',
            gap: { xs: pxToRem(10), md: pxTovW(10) },
            mt: { xs: pxToRem(10), md: pxTovW(40) },
          }}
        >
          <ImageWrapper
            name="illustration-dino"
            type="png"
            parentFolder="tempAssets"
            styles={styles.dino}
          />

          {isMobile && (
            <Box sx={{ width: { xs: '80%', md: '50%', lg: 'unset' } }}>
              {activeHomeworkContent?.assessment?.taskType !==
                TaskEnum.TASK_HOMEWORK_TEACHER_CUSTOM && (
                <Box sx={styles.startInfoBox}>
                  <ImageWrapper
                    name="user"
                    type="png"
                    parentFolder="icons"
                    styles={styles.startInfoIcon}
                  />

                  <Typography
                    variant={isIpadOnly ? 'h2' : 'cardText'}
                    fontWeight="regular"
                  >
                    {completedTasks}/{totalTasks} Submitted
                  </Typography>
                </Box>
              )}
              {loading === 'completed' && (
                <PrimaryButton
                  fullWidth={isIpadOnly ? true : false}
                  onClick={startButtonHandler}
                  disabled={
                    completedTasks >= totalTasks &&
                    activeHomeworkContent?.assessment?.taskType !==
                      TaskEnum.TASK_HOMEWORK_TEACHER_CUSTOM &&
                    !checkFeatureData({
                      assignmentConfigInfo:
                        activeHomeworkContent?.assessment?.assignmentInfo,
                      userInfo: userInfo,
                    }).reattempt
                  }
                >
                  <Typography
                    variant={isIpadOnly ? 'h2' : 'bodyText'}
                    fontWeight="bold"
                    color="neutral.lightGreen"
                  >
                    {/* {completedTasks >= totalTasks ? 'Re-attempt' : "LET'S START"} */}
                    {completedTasks === 0
                      ? "LET'S START"
                      : completedTasks >= totalTasks
                      ? 'Re-attempt'
                      : 'RESUME'}
                  </Typography>
                </PrimaryButton>
              )}
            </Box>
          )}
          {activeHomeworkContent?.assessment?.assessmentInstructions &&
            renderInstructionPanel()}
        </Box>
        {!isMobile && (
          <Box sx={{ width: { xs: '80%', md: '50%', lg: 'unset' } }}>
            {activeHomeworkContent?.assessment?.taskType !==
              TaskEnum.TASK_HOMEWORK_TEACHER_CUSTOM && (
              <Box sx={styles.startInfoBox}>
                <ImageWrapper
                  name="user"
                  type="png"
                  parentFolder="icons"
                  styles={styles.startInfoIcon}
                />

                <Typography
                  variant={isIpadOnly ? 'h2' : 'cardText'}
                  fontWeight="regular"
                >
                  {completedTasks}/{totalTasks} Submitted
                </Typography>
              </Box>
            )}
            {loading === 'completed' && (
              <PrimaryButton
                fullWidth={isIpadOnly ? true : false}
                onClick={startButtonHandler}
                disabled={
                  completedTasks >= totalTasks &&
                  activeHomeworkContent?.assessment?.taskType !==
                    TaskEnum.TASK_HOMEWORK_TEACHER_CUSTOM &&
                  !checkFeatureData({
                    assignmentConfigInfo:
                      activeHomeworkContent?.assessment?.assignmentInfo,
                    userInfo: userInfo,
                  }).reattempt
                }
              >
                <Typography
                  variant={isIpadOnly ? 'h2' : 'bodyText'}
                  fontWeight="bold"
                  color="neutral.lightGreen"
                >
                  {/* {completedTasks >= totalTasks ? 'Re-attempt' : "LET'S START"} */}
                  {completedTasks === 0
                    ? activeHomeworkStudentResponse?.assessmentAttemptStatus ===
                      TaskStudentAttemptStatusEnum.TASK_STUDENT_STATUS_IN_PROGRESS
                      ? 'RESUME'
                      : activeHomeworkStudentResponse?.assessmentAttemptStatus ===
                        TaskStudentAttemptStatusEnum.TASK_STUDENT_STATUS_TIMED_OUT
                      ? 'Re-attempt'
                      : "LET'S START"
                    : completedTasks >= totalTasks
                    ? 'Re-attempt'
                    : 'RESUME'}
                </Typography>
              </PrimaryButton>
            )}
          </Box>
        )}
      </Box>
    </Box>
  );
};
