import { Box, Typography } from '@mui/material';

import { Timestamp } from '@bufbuild/protobuf';
import {
  AssessInfoCard,
  EarlyHomeworkPopup,
  FullWidthSectionList,
  IStyles,
  InfoDisplayCard,
  PrimaryButton,
  deserify,
  formatSecondsToDateTimeString,
  getMediaBasePath,
  pxToRem,
  pxTovW,
  remainingTimeInHours,
  roundNumberTo2Decimal,
} from '@geneo2-web/shared-ui';
import {
  Question,
  Question_QuestionEnum,
} from '@protos/content_management/content.db_pb';
import {
  AssessmentEnum,
  TaskStudentAttemptStatusEnum,
} from '@protos/learning_management/lms.db_pb';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { useGlobalContext } from '../../../app/Context/GlobalContextProvider';
import { useAppSelector } from '../../../reduxStore/reduxHooks';
import {
  HOME,
  HOMEWORK_STUDENT_DASHBOARD,
} from '../../../routeHandling/RoutesNomenclature';
import { onHomeworkCardClick } from '../../../utils/homework';
import { interactionEvent } from '../../Auth/Login/login_logout.events';
import { setToastInfo } from '../../Home/reducer/homeDashboard.slice';
import { setHomeworkEndPath } from '../reducer/homework.slice';
import { aiHomeworkCloseEvent } from '../utils_homework/homework.events';
import { HomeworkRecommendations } from './component/HomeworkRecommendations';
import { HwSubmittedHeadings } from './component/HwSubmittedHeadings';
import { useDownloadContext } from '../../../app/Context/DownloadContextProviderV2';
import { checkFeatureData } from '../utils_homework/homeworkUtil';

const styles: IStyles = {
  root: {
    // boxSizing: 'border-box',
  },

  headingAndButtonBox: {
    width: { md: '50%', lg: pxTovW(400) },
    paddingRight: { md: pxTovW(100) },
    margin: 'auto',
  },

  buttonBox: {
    width: { xs: pxToRem(294), md: 'max-content' },
    margin: 'auto',
    mb: { xs: pxToRem(20), md: pxTovW(20) },
  },

  sectionListBox: {
    p: { xs: `0`, md: pxToRem(20), lg: `0 ${pxTovW(210)}` },
  },
};

export const HwSubmitted = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const { isIpadOnly } = useGlobalContext();
  const { isOffline } = useDownloadContext();
  const pathSegments = location.pathname.split('/');
  const [startTime, setStartTime] = useState<Timestamp | undefined>(undefined);
  const homework_id =
    new URLSearchParams(location.search).get('homeworkId') || undefined;
  const { userInfo } = deserify(useAppSelector((state) => state.auth));
  const studentId = deserify(
    useAppSelector((state) => state.auth.userInfo?.studentProfileId)
  );
  const active_homework_content = deserify(
    useAppSelector((state) => state.homework.active_homework_content)
  );
  const activeHomeworkContent =
    active_homework_content && homework_id
      ? active_homework_content[homework_id]
      : undefined;
  const homeworkAttemptResult = deserify(
    useAppSelector((state) => state.homework.active_homework_attempt_result)
  );
  const { homework_end_path, selected_homework_data } = useAppSelector(
    (state) => state.homework
  );
  const homeHomeworkList = deserify(
    useAppSelector((state) => state.home.home_homework_list)
  );
  const homeworkDashboardList = deserify(
    useAppSelector((state) => state.homework.home_homework_list)
  );
  const activeHomeworkList =
    homework_end_path === HOME ? homeHomeworkList : homeworkDashboardList;
  const currentResult =
    homework_id && homeworkAttemptResult
      ? homeworkAttemptResult[homework_id]
      : undefined;
  const { maxScore, responseScore } = currentResult || {};
  const scorePercent = roundNumberTo2Decimal(
    maxScore && responseScore ? (responseScore * 100) / maxScore : 0
  );
  const { setSelectedFunction } = useGlobalContext();

  useEffect(() => {
    (async () => {
      await aiHomeworkCloseEvent({
        homeworkId: selected_homework_data?.homeworkId,
        isOffline: isOffline,
      });
    })();

    const redirectToHomeworkDashboard = async () => {
      navigate(homework_end_path ? homework_end_path : HOME);
      dispatch(setHomeworkEndPath(undefined));
    };
    setSelectedFunction(() => redirectToHomeworkDashboard);
    return () => {
      setSelectedFunction(null);
    };
  }, []);

  const nextHomeworks = activeHomeworkList
    ?.filter((val) => val.assessmentId !== Number(homework_id))
    .map((val, index) => (
      <AssessInfoCard
        key={'Next_homework_' + index}
        // image={val.assessmentPosterImgUrl}
        // variant={isIpadOnly ? 'large' : 'small'}
        homeworkItem={val}
        // iconDetails={[
        //   {
        //     iconName: 'questions',
        //     text: val.noOfQuestions.toString(),
        //     label: 'Questions',
        //   },
        //   {
        //     iconName: 'clock',
        //     text: remainingTimeInHours(val.endDate),
        //     label: 'Remaining',
        //   },
        // ]}
        // rootStyle={{
        //   width: { xs: '100%', md: pxToRem(250), lg: pxTovW(347) },
        //   height: { xs: pxToRem(165), md: pxToRem(100), lg: pxTovW(170) },
        //   maxWidth: { xs: '100%', md: pxToRem(250), lg: pxTovW(347) },
        // }}
        onCardClick={async () => {
          if (!studentId) {
            return;
          }
          if (val.startDate) {
            if (new Date(new Timestamp(val.startDate).toDate()) > new Date()) {
              setStartTime(val.startDate);
              return;
            }
          }
          onHomeworkCardClick(
            dispatch,
            navigate,
            val.assessmentId,
            studentId,
            'active'
          );

          await interactionEvent({
            url: '',
            context: 'next_homework',
            name: 'HOMEWORK_OPEN',
            pathSegments: pathSegments,
            isOffline: isOffline,
          });
        }}
      />
    ));

  const fractionClickHandler = () => {
    //
  };

  const reattemptClickHandler = () => {
    //
    if (!studentId || !homework_id) {
      return;
    }
    if (
      new Timestamp(
        activeHomeworkContent?.assessment?.assessmentTargetDate
      ).toDate() < new Date(Date.now())
    ) {
      dispatch(
        setToastInfo({
          label: 'Apologies, Deadline for reattempt has passed.',
          variant: 'error',
          open: true,
        })
      );
      return;
    }
    onHomeworkCardClick(dispatch, navigate, Number(homework_id), studentId);
  };

  return (
    <Box sx={styles.root}>
      <Box sx={styles.headingAndButtonBox}>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            // paddingRight: { md: pxTovW(100) },
          }}
        >
          <HwSubmittedHeadings
            reattemptAllowed={
              checkFeatureData({
                assignmentConfigInfo:
                  activeHomeworkContent?.assessment?.assignmentInfo,
                userInfo: userInfo,
              }).reattempt
            }
            assessmentType={activeHomeworkContent?.assessment?.assessmentType}
            taskType={activeHomeworkContent?.assessment?.taskType}
            scorePercent={Math.round(scorePercent)}
            fractionClickHandler={fractionClickHandler}
            topicName={activeHomeworkContent?.assessment?.moduleName}
            isSubjectiveHw={activeHomeworkContent?.assessmentContent.some((q) =>
              checkSubjectiveQuestion(q)
            )}
          />
        </Box>
        {activeHomeworkContent?.assessment?.assessmentType ===
          AssessmentEnum.ASSESSMENT_FORMATIVE_ASSESSMENT && (
          <Box
            sx={{
              width: 'max-content',
              backgroundColor: '#EFF7FD',
              borderRadius: '10px',
              padding: '10px',
              marginBottom: '10px',
              marginX: 'auto',
              border: '1px solid #007CDC',
            }}
          >
            <Typography variant="h4" fontWeight="bold">
              {`Results: ${formatSecondsToDateTimeString(
                activeHomeworkContent.assessment.assessmentTargetDate?.seconds
              )}`}
            </Typography>
          </Box>
        )}
        <Box sx={styles.buttonBox}>
          {checkFeatureData({
            assignmentConfigInfo:
              activeHomeworkContent?.assessment?.assignmentInfo,
            userInfo: userInfo,
          }).reattempt ? (
            <PrimaryButton onClick={reattemptClickHandler}>
              <Typography variant="h3" color="common.white" fontWeight="bold">
                REATTEMPT
              </Typography>
            </PrimaryButton>
          ) : (
            <PrimaryButton onClick={() => navigate(HOMEWORK_STUDENT_DASHBOARD)}>
              <Typography variant="h3" color="common.white" fontWeight="bold">
                GO TO HOMEPAGE
              </Typography>
            </PrimaryButton>
          )}
        </Box>
      </Box>

      <Box sx={styles.sectionListBox}>
        <FullWidthSectionList
          hideListCount
          sectionTitle={
            activeHomeworkContent?.assessment?.assessmentType ===
            AssessmentEnum.ASSESSMENT_FORMATIVE_ASSESSMENT
              ? 'Next Assessments'
              : 'Next Homeworks'
          }
          items={nextHomeworks}
        />
        <HomeworkRecommendations
          subjectId={
            Number(activeHomeworkContent?.assessment?.subjectId) || undefined
          }
          chapterId={
            Number(activeHomeworkContent?.assessment?.chapterId) || undefined
          }
          topicId={
            Number(activeHomeworkContent?.assessment?.moduleId) || undefined
          }
        />
        {/* <FullWidthSectionList
          hideListCount
          sectionTitle="_Recommended Resources"
          items={hwData.active.map((elem, index) => (
            <ContentDetailCard
              variant="medium"
              image={'/assets/shared-ui/tempAssets/lessonImage1.png'}
              heading={"ChapterSummary"}
              iconDetails={[
                {
                  iconName: 'clock',
                  text: '15 Min',
                },
                {
                  iconName: 'questions',
                  text: 'Reading',
                },
              ]}
              rootStyle={{
                backgroundColor: 'common.white'
              }}
            />
          ))}
        /> */}
      </Box>
      <EarlyHomeworkPopup
        open={!!startTime}
        okHandler={() => setStartTime(undefined)}
        startTime={startTime}
      />
    </Box>
  );
};

const hwData = {
  active: [
    {
      subject: 'Science',
      chapter: 'Friction',
      hwName: 'My Homework',
      totalQuestion: '20',
      timeLeft: '10 hrs',
      name: 'Deepali',
      completed: '0',
    },
    {
      subject: 'Science',
      chapter: 'Friction',
      hwName: 'My Homework',
      totalQuestion: '20',
      timeLeft: '10 hrs',
      name: 'Deepali',
      completed: '45',
    },
    {
      subject: 'Science',
      chapter: 'Friction',
      hwName: 'My Homework',
      totalQuestion: '20',
      timeLeft: '10 hrs',
      name: 'Deepali',
      completed: '100',
    },
    {
      subject: 'Science',
      chapter: 'Friction',
      hwName: 'My Homework',
      totalQuestion: '20',
      timeLeft: '10 hrs',
      name: 'Deepali',
      completed: '0',
    },
  ],
};
const checkSubjectiveQuestion = (question: Question) => {
  if (
    question?.questionType === Question_QuestionEnum.QUESTION_TYPE_VERY_SHORT ||
    question?.questionType === Question_QuestionEnum.QUESTION_TYPE_SHORT ||
    question?.questionType === Question_QuestionEnum.QUESTION_TYPE_LONG
  ) {
    return true;
  } else {
    return false;
  }
};
