import { createPromiseClient } from '@bufbuild/connect';
import { ConnectTransportOptions } from '@bufbuild/connect-web';
import {
  ResponseStatusCodeFunction,
  lmsTransport,
  lmsTransportWithLogoutHandler,
} from './transports';
import { LmsAssessmentStudentAPIServiceV1 } from '@protos/learning_management/lms.assessment.student.apis_connect';

export const LmsAssessmentStudentAPIServiceV1Client = createPromiseClient(
  LmsAssessmentStudentAPIServiceV1,
  lmsTransport
);

export const useLmsAssessmentStudentAPIServiceV1ClientWithStatusCodeHandler = (
  options: Partial<ConnectTransportOptions>,
  responseStatusCodeFunction: ResponseStatusCodeFunction
) =>
  createPromiseClient(
    LmsAssessmentStudentAPIServiceV1,
    lmsTransportWithLogoutHandler(options, responseStatusCodeFunction)
  );
