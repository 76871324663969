// @generated by protoc-gen-connect-es v0.10.0 with parameter "target=ts"
// @generated from file src/protos/user_management/ums.common.apis.proto (package geneo.ums.common.apis, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { ActiveSessionRequest, ActiveSessionResponse, ContentTokenRequest, ContentTokenResponse, DeviceInfoRequest, DeviceInfoResponse, FetchCloseSessionRequest, FetchCloseSessionResponse, GenerateCustomTokenWithExpiryRequest, GenerateCustomTokenWithExpiryResponse, GetStudentNotificationSettingsRequest, GetStudentNotificationSettingsResponse, GetStudentSupportRequest, GetStudentSupportResponse, RefreshUserAccessTokenRequest, RefreshUserAccessTokenResponse, ValidateLoginSessionRequest, ValidateLoginSessionResponse } from "./ums.common.apis_pb";
import { MethodKind } from "@bufbuild/protobuf";

/**
 * @generated from service geneo.ums.common.apis.UmsUtilityApiServiceV1
 */
export const UmsUtilityApiServiceV1 = {
  typeName: "geneo.ums.common.apis.UmsUtilityApiServiceV1",
  methods: {
    /**
     * @generated from rpc geneo.ums.common.apis.UmsUtilityApiServiceV1.GetStudentNotificationSettings
     */
    getStudentNotificationSettings: {
      name: "GetStudentNotificationSettings",
      I: GetStudentNotificationSettingsRequest,
      O: GetStudentNotificationSettingsResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc geneo.ums.common.apis.UmsUtilityApiServiceV1.GetStudentSupportData
     */
    getStudentSupportData: {
      name: "GetStudentSupportData",
      I: GetStudentSupportRequest,
      O: GetStudentSupportResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc geneo.ums.common.apis.UmsUtilityApiServiceV1.fetchContentToken
     */
    fetchContentToken: {
      name: "fetchContentToken",
      I: ContentTokenRequest,
      O: ContentTokenResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc geneo.ums.common.apis.UmsUtilityApiServiceV1.saveDeviceInfo
     */
    saveDeviceInfo: {
      name: "saveDeviceInfo",
      I: DeviceInfoRequest,
      O: DeviceInfoResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc geneo.ums.common.apis.UmsUtilityApiServiceV1.upsertActiveSession
     */
    upsertActiveSession: {
      name: "upsertActiveSession",
      I: ActiveSessionRequest,
      O: ActiveSessionResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc geneo.ums.common.apis.UmsUtilityApiServiceV1.forceCloseSessions
     */
    forceCloseSessions: {
      name: "forceCloseSessions",
      I: FetchCloseSessionRequest,
      O: FetchCloseSessionResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc geneo.ums.common.apis.UmsUtilityApiServiceV1.validateLoginSession
     */
    validateLoginSession: {
      name: "validateLoginSession",
      I: ValidateLoginSessionRequest,
      O: ValidateLoginSessionResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc geneo.ums.common.apis.UmsUtilityApiServiceV1.refreshUserAccessToken
     */
    refreshUserAccessToken: {
      name: "refreshUserAccessToken",
      I: RefreshUserAccessTokenRequest,
      O: RefreshUserAccessTokenResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc geneo.ums.common.apis.UmsUtilityApiServiceV1.generateCustomTokenWithExpiry
     */
    generateCustomTokenWithExpiry: {
      name: "generateCustomTokenWithExpiry",
      I: GenerateCustomTokenWithExpiryRequest,
      O: GenerateCustomTokenWithExpiryResponse,
      kind: MethodKind.Unary,
    },
  }
} as const;

