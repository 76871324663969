import {
  IStyles,
  InfoBar,
  InstructionsPanel,
  deserify,
  formatDateAsDayMonth,
  pxToRem,
  pxTovW,
} from '@geneo2-web/shared-ui';
import { Typography } from '@mui/material';
import { Box, display } from '@mui/system';
import { useAppSelector } from 'apps/student/src/reduxStore/reduxHooks';

import { useLocation } from 'react-router-dom';

const styles: IStyles = {
  root: {
    display: 'flex',
    flexDirection: 'column',
    p: {
      xs: `${pxToRem(20)} ${pxToRem(18)} `,
      lg: `${pxTovW(37)} ${pxTovW(240)}`,
    },
    gap: { xs: pxToRem(20), md: pxTovW(30) },
  },
  mainContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    flexWrap: 'wrap',
    gap: { xs: pxToRem(20), md: pxTovW(20) },
    position: 'relative',
  },
  headingTypo: {
    display: '-webkit-box',
    WebkitLineClamp: 1,
    WebkitBoxOrient: 'vertical',
    overflow: 'hidden',
    fontWeight: 'medium',
  },
  leftPanel: {
    width: { xs: '100%', md: '45%', lg: pxTovW(520) },
    display: 'flex',
    flexDirection: 'column',
    gap: { xs: pxToRem(20), md: pxTovW(30) },
  },
  rightPanel: {
    width: { xs: '100%', md: '50%', lg: pxTovW(814) },
    height: { md: '70vh' },
    maxHeight: { xs: pxToRem(420), md: pxTovW(666) },
    display: 'flex',
    flexDirection: 'column',
    gap: { xs: pxToRem(20), md: pxTovW(30) },
    paddingBottom: { xs: pxToRem(75), md: 0 },
  },
};

export const OfflineEndedCustomHw = () => {
  const location = useLocation();
  const homework_id =
    new URLSearchParams(location.search).get('homeworkId') || undefined;
  const { active_homework_student_response, active_homework_content } =
    deserify(useAppSelector((state) => state.homework));

  const activeHomeworkContent =
    active_homework_content && homework_id
      ? active_homework_content[homework_id]
      : undefined;
  const activeHomeworkStudentResponse =
    active_homework_student_response && homework_id
      ? active_homework_student_response[homework_id]
      : undefined;

  return (
    <Box sx={styles.root}>
      <Typography variant="h1" sx={styles.headingTypo}>
        {activeHomeworkContent?.assessment?.assessmentTitle}
      </Typography>

      <Box sx={styles.mainContainer}>
        <Box sx={styles.leftPanel}>
          <InfoBar
            contentList={[
              {
                iconName: 'calender',
                heading: formatDateAsDayMonth(
                  activeHomeworkContent?.assessment?.assessmentTargetDate
                ),
                subHeading: 'Deadline',
              },

              ...(activeHomeworkStudentResponse?.metrics?.maxScore
                ? [
                    {
                      iconName: 'marks',
                      heading: ` ${activeHomeworkStudentResponse?.metrics?.responseScore}/${activeHomeworkStudentResponse?.metrics?.maxScore}`,
                      subHeading: 'Marks',
                    },
                  ]
                : []),
            ]}
          />
        </Box>

        <Box sx={styles.rightPanel}>
          <Box>
            <InstructionsPanel
              customSx={{ display: 'flex', flexGrow: 1 }}
              AttachmentList={
                activeHomeworkContent?.assessment?.taskUploads.filter(
                  (e) => e.isDeleted === false
                ) || undefined
              }
              // Katexvalue={TextEditorValue?.toString() || '<p></p>'}
              Katexvalue={
                activeHomeworkContent?.assessment?.assessmentInstructions.join(
                  '\n'
                ) || '<p></p>'
              }
            />
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
