// @generated by protoc-gen-es v1.2.1 with parameter "target=ts"
// @generated from file src/protos/learning_management/lms.assessment.student.apis.proto (package geneo.lms.assessment.student.apis, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import type { BinaryReadOptions, FieldList, JsonReadOptions, JsonValue, PartialMessage, PlainMessage } from "@bufbuild/protobuf";
import { Message, proto3, protoInt64, Timestamp } from "@bufbuild/protobuf";
import { AssessmentEnum, AssessmentSessionModeEnum, CloudUpload, MetricsModel, StudentAssessmentResponse, SubmissionType, TaskActiveStatusEnum, TaskEnum, TaskEvaluationStatusEnum, TaskMetaInfo, TaskStudentAttemptStatusEnum } from "@protos/learning_management/lms.db_pb";
import { DifficultyLevelEnum, Module_ModuleCategoryEnum } from "@protos/content_management/content.db_pb";
import { UploadFileResponse } from "@protos/learning_management/lms.hw.student.apis_pb";

/**
 * @generated from enum geneo.lms.assessment.student.apis.AssessmentListEnum
 */
export enum AssessmentListEnum {
  /**
   * @generated from enum value: ASSESSMENT_UNDEFINED = 0;
   */
  ASSESSMENT_UNDEFINED = 0,

  /**
   * @generated from enum value: ASSESSMENT_ACTIVE = 1;
   */
  ASSESSMENT_ACTIVE = 1,

  /**
   * @generated from enum value: ASSESSMENT_ENDED = 2;
   */
  ASSESSMENT_ENDED = 2,
}
// Retrieve enum metadata with: proto3.getEnumType(AssessmentListEnum)
proto3.util.setEnumType(AssessmentListEnum, "geneo.lms.assessment.student.apis.AssessmentListEnum", [
  { no: 0, name: "ASSESSMENT_UNDEFINED" },
  { no: 1, name: "ASSESSMENT_ACTIVE" },
  { no: 2, name: "ASSESSMENT_ENDED" },
]);

/**
 * @generated from message geneo.lms.assessment.student.apis.GetStudentAssessmentListRequest
 */
export class GetStudentAssessmentListRequest extends Message<GetStudentAssessmentListRequest> {
  /**
   * @generated from field: int64 student_id = 1;
   */
  studentId = protoInt64.zero;

  /**
   * @generated from field: optional int32 page_number = 2;
   */
  pageNumber?: number;

  /**
   * @generated from field: optional int32 subject_id = 3;
   */
  subjectId?: number;

  /**
   * @generated from field: optional geneo.lms.db.AssessmentEnum assessment_type = 4;
   */
  assessmentType?: AssessmentEnum;

  /**
   * @generated from field: optional geneo.lms.assessment.student.apis.AssessmentListEnum assessment_list_type = 5;
   */
  assessmentListType?: AssessmentListEnum;

  constructor(data?: PartialMessage<GetStudentAssessmentListRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.lms.assessment.student.apis.GetStudentAssessmentListRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "student_id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 2, name: "page_number", kind: "scalar", T: 5 /* ScalarType.INT32 */, opt: true },
    { no: 3, name: "subject_id", kind: "scalar", T: 5 /* ScalarType.INT32 */, opt: true },
    { no: 4, name: "assessment_type", kind: "enum", T: proto3.getEnumType(AssessmentEnum), opt: true },
    { no: 5, name: "assessment_list_type", kind: "enum", T: proto3.getEnumType(AssessmentListEnum), opt: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetStudentAssessmentListRequest {
    return new GetStudentAssessmentListRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetStudentAssessmentListRequest {
    return new GetStudentAssessmentListRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetStudentAssessmentListRequest {
    return new GetStudentAssessmentListRequest().fromJsonString(jsonString, options);
  }

  static equals(a: GetStudentAssessmentListRequest | PlainMessage<GetStudentAssessmentListRequest> | undefined, b: GetStudentAssessmentListRequest | PlainMessage<GetStudentAssessmentListRequest> | undefined): boolean {
    return proto3.util.equals(GetStudentAssessmentListRequest, a, b);
  }
}

/**
 * @generated from message geneo.lms.assessment.student.apis.GetStudentAssessmentListResponse
 */
export class GetStudentAssessmentListResponse extends Message<GetStudentAssessmentListResponse> {
  /**
   * @generated from field: int32 status = 1;
   */
  status = 0;

  /**
   * @generated from field: string code = 2;
   */
  code = "";

  /**
   * @generated from field: string message = 3;
   */
  message = "";

  /**
   * @generated from field: geneo.lms.assessment.student.apis.StudentAssessmentList data = 4;
   */
  data?: StudentAssessmentList;

  constructor(data?: PartialMessage<GetStudentAssessmentListResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.lms.assessment.student.apis.GetStudentAssessmentListResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "status", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 2, name: "code", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "message", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "data", kind: "message", T: StudentAssessmentList },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetStudentAssessmentListResponse {
    return new GetStudentAssessmentListResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetStudentAssessmentListResponse {
    return new GetStudentAssessmentListResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetStudentAssessmentListResponse {
    return new GetStudentAssessmentListResponse().fromJsonString(jsonString, options);
  }

  static equals(a: GetStudentAssessmentListResponse | PlainMessage<GetStudentAssessmentListResponse> | undefined, b: GetStudentAssessmentListResponse | PlainMessage<GetStudentAssessmentListResponse> | undefined): boolean {
    return proto3.util.equals(GetStudentAssessmentListResponse, a, b);
  }
}

/**
 * @generated from message geneo.lms.assessment.student.apis.StudentAssessmentList
 */
export class StudentAssessmentList extends Message<StudentAssessmentList> {
  /**
   * @generated from field: int64 student_id = 1;
   */
  studentId = protoInt64.zero;

  /**
   * @generated from field: geneo.lms.assessment.student.apis.StudentAssessments all_assessment = 2;
   */
  allAssessment?: StudentAssessments;

  /**
   * @generated from field: optional geneo.lms.assessment.student.apis.StudentAssessCountInfo assessment_list_info = 3;
   */
  assessmentListInfo?: StudentAssessCountInfo;

  constructor(data?: PartialMessage<StudentAssessmentList>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.lms.assessment.student.apis.StudentAssessmentList";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "student_id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 2, name: "all_assessment", kind: "message", T: StudentAssessments },
    { no: 3, name: "assessment_list_info", kind: "message", T: StudentAssessCountInfo, opt: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): StudentAssessmentList {
    return new StudentAssessmentList().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): StudentAssessmentList {
    return new StudentAssessmentList().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): StudentAssessmentList {
    return new StudentAssessmentList().fromJsonString(jsonString, options);
  }

  static equals(a: StudentAssessmentList | PlainMessage<StudentAssessmentList> | undefined, b: StudentAssessmentList | PlainMessage<StudentAssessmentList> | undefined): boolean {
    return proto3.util.equals(StudentAssessmentList, a, b);
  }
}

/**
 * @generated from message geneo.lms.assessment.student.apis.StudentAssessCountInfo
 */
export class StudentAssessCountInfo extends Message<StudentAssessCountInfo> {
  /**
   * @generated from field: int32 total_assessment_count = 1;
   */
  totalAssessmentCount = 0;

  /**
   * @generated from field: int32 active_assessment_count = 2;
   */
  activeAssessmentCount = 0;

  /**
   * @generated from field: int32 ended_assessment_count = 3;
   */
  endedAssessmentCount = 0;

  /**
   * @generated from field: int32 count_in_pagination = 4;
   */
  countInPagination = 0;

  constructor(data?: PartialMessage<StudentAssessCountInfo>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.lms.assessment.student.apis.StudentAssessCountInfo";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "total_assessment_count", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 2, name: "active_assessment_count", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 3, name: "ended_assessment_count", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 4, name: "count_in_pagination", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): StudentAssessCountInfo {
    return new StudentAssessCountInfo().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): StudentAssessCountInfo {
    return new StudentAssessCountInfo().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): StudentAssessCountInfo {
    return new StudentAssessCountInfo().fromJsonString(jsonString, options);
  }

  static equals(a: StudentAssessCountInfo | PlainMessage<StudentAssessCountInfo> | undefined, b: StudentAssessCountInfo | PlainMessage<StudentAssessCountInfo> | undefined): boolean {
    return proto3.util.equals(StudentAssessCountInfo, a, b);
  }
}

/**
 * @generated from message geneo.lms.assessment.student.apis.StudentAssessments
 */
export class StudentAssessments extends Message<StudentAssessments> {
  /**
   * @generated from field: repeated geneo.lms.assessment.student.apis.StudentAssessmentTask active = 2;
   */
  active: StudentAssessmentTask[] = [];

  /**
   * @generated from field: repeated geneo.lms.assessment.student.apis.StudentAssessmentTask ended = 3;
   */
  ended: StudentAssessmentTask[] = [];

  constructor(data?: PartialMessage<StudentAssessments>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.lms.assessment.student.apis.StudentAssessments";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 2, name: "active", kind: "message", T: StudentAssessmentTask, repeated: true },
    { no: 3, name: "ended", kind: "message", T: StudentAssessmentTask, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): StudentAssessments {
    return new StudentAssessments().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): StudentAssessments {
    return new StudentAssessments().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): StudentAssessments {
    return new StudentAssessments().fromJsonString(jsonString, options);
  }

  static equals(a: StudentAssessments | PlainMessage<StudentAssessments> | undefined, b: StudentAssessments | PlainMessage<StudentAssessments> | undefined): boolean {
    return proto3.util.equals(StudentAssessments, a, b);
  }
}

/**
 * @generated from message geneo.lms.assessment.student.apis.StudentAssessmentTask
 */
export class StudentAssessmentTask extends Message<StudentAssessmentTask> {
  /**
   * ID of the homework
   *
   * @generated from field: int32 assessment_id = 1;
   */
  assessmentId = 0;

  /**
   * Title of the homework
   *
   * @generated from field: string assessment_title = 2;
   */
  assessmentTitle = "";

  /**
   * Description of the homework
   *
   * @generated from field: string description = 3;
   */
  description = "";

  /**
   * URL of the homework poster image
   *
   * @generated from field: string assessment_poster_img_url = 4;
   */
  assessmentPosterImgUrl = "";

  /**
   * Difficulty level of the homework
   *
   * @generated from field: geneo.content.db.DifficultyLevelEnum difficulty_level = 5;
   */
  difficultyLevel = DifficultyLevelEnum.DIFFICULTY_LEVEL_UNDEFINED;

  /**
   * Estimated time for which the homework is assigned
   *
   * @generated from field: float estimated_time_in_min = 6;
   */
  estimatedTimeInMin = 0;

  /**
   * ID of the teacher
   *
   * @generated from field: int64 teacher_id = 7;
   */
  teacherId = protoInt64.zero;

  /**
   * Name of the teacher who assigned the homework
   *
   * @generated from field: string teacher_name = 8;
   */
  teacherName = "";

  /**
   * URL of the teacher's profile image
   *
   * @generated from field: string teacher_profile_image_url = 9;
   */
  teacherProfileImageUrl = "";

  /**
   * Active status of the homework
   *
   * @generated from field: geneo.lms.db.TaskActiveStatusEnum assessment_active_status = 10;
   */
  assessmentActiveStatus = TaskActiveStatusEnum.TASK_ACTIVE_STATUS_UNDEFINED;

  /**
   * Attempt status of the homework for students
   *
   * @generated from field: geneo.lms.db.TaskStudentAttemptStatusEnum student_attempt_status = 11;
   */
  studentAttemptStatus = TaskStudentAttemptStatusEnum.TASK_STUDENT_STATUS_UNDEFINED;

  /**
   * ID of the course the homework belongs to
   *
   * @generated from field: int32 subject_id = 12;
   */
  subjectId = 0;

  /**
   * ID of the module the homework belongs to
   *
   * @generated from field: int32 module_id = 13;
   */
  moduleId = 0;

  /**
   * ID of the module
   *
   * @generated from field: geneo.content.db.Module.ModuleCategoryEnum module_category = 14;
   */
  moduleCategory = Module_ModuleCategoryEnum.MODULE_CATEGORY_UNDEFINED;

  /**
   * Subject of the homework
   *
   * @generated from field: string subject = 15;
   */
  subject = "";

  /**
   * Number of questions in the homework
   *
   * @generated from field: int32 no_of_questions = 16;
   */
  noOfQuestions = 0;

  /**
   * Start date of the homework
   *
   * @generated from field: google.protobuf.Timestamp start_date = 17;
   */
  startDate?: Timestamp;

  /**
   * End date of the homework
   *
   * @generated from field: google.protobuf.Timestamp end_date = 18;
   */
  endDate?: Timestamp;

  /**
   * Score received for the homework
   *
   * @generated from field: float score_percent = 19;
   */
  scorePercent = 0;

  /**
   * @generated from field: string module_name = 20;
   */
  moduleName = "";

  /**
   * @generated from field: optional float max_marks = 21;
   */
  maxMarks?: number;

  /**
   * Only for teacher created CUSTOM hws
   *
   * @generated from field: optional geneo.lms.db.SubmissionType submission_type = 22;
   */
  submissionType?: SubmissionType;

  /**
   * @generated from field: geneo.lms.db.TaskEnum task_type = 23;
   */
  taskType = TaskEnum.TASK_UNDEFINED;

  /**
   * @generated from field: repeated geneo.lms.db.CloudUpload user_uploads = 24;
   */
  userUploads: CloudUpload[] = [];

  /**
   * @generated from field: optional geneo.lms.db.AssessmentEnum assessment_type = 25;
   */
  assessmentType?: AssessmentEnum;

  /**
   * @generated from field: geneo.lms.db.TaskMetaInfo task_meta_info = 26;
   */
  taskMetaInfo?: TaskMetaInfo;

  constructor(data?: PartialMessage<StudentAssessmentTask>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.lms.assessment.student.apis.StudentAssessmentTask";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "assessment_id", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 2, name: "assessment_title", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "description", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "assessment_poster_img_url", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "difficulty_level", kind: "enum", T: proto3.getEnumType(DifficultyLevelEnum) },
    { no: 6, name: "estimated_time_in_min", kind: "scalar", T: 2 /* ScalarType.FLOAT */ },
    { no: 7, name: "teacher_id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 8, name: "teacher_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 9, name: "teacher_profile_image_url", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 10, name: "assessment_active_status", kind: "enum", T: proto3.getEnumType(TaskActiveStatusEnum) },
    { no: 11, name: "student_attempt_status", kind: "enum", T: proto3.getEnumType(TaskStudentAttemptStatusEnum) },
    { no: 12, name: "subject_id", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 13, name: "module_id", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 14, name: "module_category", kind: "enum", T: proto3.getEnumType(Module_ModuleCategoryEnum) },
    { no: 15, name: "subject", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 16, name: "no_of_questions", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 17, name: "start_date", kind: "message", T: Timestamp },
    { no: 18, name: "end_date", kind: "message", T: Timestamp },
    { no: 19, name: "score_percent", kind: "scalar", T: 2 /* ScalarType.FLOAT */ },
    { no: 20, name: "module_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 21, name: "max_marks", kind: "scalar", T: 2 /* ScalarType.FLOAT */, opt: true },
    { no: 22, name: "submission_type", kind: "enum", T: proto3.getEnumType(SubmissionType), opt: true },
    { no: 23, name: "task_type", kind: "enum", T: proto3.getEnumType(TaskEnum) },
    { no: 24, name: "user_uploads", kind: "message", T: CloudUpload, repeated: true },
    { no: 25, name: "assessment_type", kind: "enum", T: proto3.getEnumType(AssessmentEnum), opt: true },
    { no: 26, name: "task_meta_info", kind: "message", T: TaskMetaInfo },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): StudentAssessmentTask {
    return new StudentAssessmentTask().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): StudentAssessmentTask {
    return new StudentAssessmentTask().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): StudentAssessmentTask {
    return new StudentAssessmentTask().fromJsonString(jsonString, options);
  }

  static equals(a: StudentAssessmentTask | PlainMessage<StudentAssessmentTask> | undefined, b: StudentAssessmentTask | PlainMessage<StudentAssessmentTask> | undefined): boolean {
    return proto3.util.equals(StudentAssessmentTask, a, b);
  }
}

/**
 * @generated from message geneo.lms.assessment.student.apis.StudentAssessmentContent
 */
export class StudentAssessmentContent extends Message<StudentAssessmentContent> {
  /**
   * ID of the homework
   *
   * @generated from field: int32 assessment_id = 1;
   */
  assessmentId = 0;

  /**
   * ID of the student
   *
   * @generated from field: int64 student_id = 2;
   */
  studentId = protoInt64.zero;

  /**
   * Date when the task was commenced
   *
   * @generated from field: google.protobuf.Timestamp task_start_time = 3;
   */
  taskStartTime?: Timestamp;

  /**
   * Target date for task completion
   *
   * @generated from field: google.protobuf.Timestamp task_target_date = 4;
   */
  taskTargetDate?: Timestamp;

  /**
   * Date when the task was completed
   *
   * @generated from field: google.protobuf.Timestamp task_completed_date = 5;
   */
  taskCompletedDate?: Timestamp;

  /**
   * Active status of the homework
   *
   * @generated from field: geneo.lms.db.TaskActiveStatusEnum assessment_active_status = 6;
   */
  assessmentActiveStatus = TaskActiveStatusEnum.TASK_ACTIVE_STATUS_UNDEFINED;

  /**
   * Attempt status of the homework
   *
   * @generated from field: geneo.lms.db.TaskStudentAttemptStatusEnum assessment_attempt_status = 7;
   */
  assessmentAttemptStatus = TaskStudentAttemptStatusEnum.TASK_STUDENT_STATUS_UNDEFINED;

  /**
   * Evaluation status of the homework
   *
   * @generated from field: geneo.lms.db.TaskEvaluationStatusEnum assessment_evaluation_status = 8;
   */
  assessmentEvaluationStatus = TaskEvaluationStatusEnum.TASK_EVALUATION_STATUS_UNDEFINED;

  /**
   * Metrics associated with the homework response
   *
   * @generated from field: geneo.lms.db.MetricsModel metrics = 9;
   */
  metrics?: MetricsModel;

  /**
   * List of student's responses to the homework questions
   *
   * @generated from field: repeated geneo.lms.db.StudentAssessmentResponse responses = 10;
   */
  responses: StudentAssessmentResponse[] = [];

  /**
   * Creation timestamp
   *
   * @generated from field: google.protobuf.Timestamp created_on = 11;
   */
  createdOn?: Timestamp;

  /**
   * Last modification timestamp
   *
   * @generated from field: optional google.protobuf.Timestamp modified_on = 12;
   */
  modifiedOn?: Timestamp;

  /**
   * @generated from field: repeated geneo.lms.db.CloudUpload user_uploads = 13;
   */
  userUploads: CloudUpload[] = [];

  /**
   * have to include assignment confige
   *
   * @generated from field: int32 total_time = 14;
   */
  totalTime = 0;

  constructor(data?: PartialMessage<StudentAssessmentContent>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.lms.assessment.student.apis.StudentAssessmentContent";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "assessment_id", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 2, name: "student_id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 3, name: "task_start_time", kind: "message", T: Timestamp },
    { no: 4, name: "task_target_date", kind: "message", T: Timestamp },
    { no: 5, name: "task_completed_date", kind: "message", T: Timestamp },
    { no: 6, name: "assessment_active_status", kind: "enum", T: proto3.getEnumType(TaskActiveStatusEnum) },
    { no: 7, name: "assessment_attempt_status", kind: "enum", T: proto3.getEnumType(TaskStudentAttemptStatusEnum) },
    { no: 8, name: "assessment_evaluation_status", kind: "enum", T: proto3.getEnumType(TaskEvaluationStatusEnum) },
    { no: 9, name: "metrics", kind: "message", T: MetricsModel },
    { no: 10, name: "responses", kind: "message", T: StudentAssessmentResponse, repeated: true },
    { no: 11, name: "created_on", kind: "message", T: Timestamp },
    { no: 12, name: "modified_on", kind: "message", T: Timestamp, opt: true },
    { no: 13, name: "user_uploads", kind: "message", T: CloudUpload, repeated: true },
    { no: 14, name: "total_time", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): StudentAssessmentContent {
    return new StudentAssessmentContent().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): StudentAssessmentContent {
    return new StudentAssessmentContent().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): StudentAssessmentContent {
    return new StudentAssessmentContent().fromJsonString(jsonString, options);
  }

  static equals(a: StudentAssessmentContent | PlainMessage<StudentAssessmentContent> | undefined, b: StudentAssessmentContent | PlainMessage<StudentAssessmentContent> | undefined): boolean {
    return proto3.util.equals(StudentAssessmentContent, a, b);
  }
}

/**
 * @generated from message geneo.lms.assessment.student.apis.CreateStudentAssessmentSessionRequest
 */
export class CreateStudentAssessmentSessionRequest extends Message<CreateStudentAssessmentSessionRequest> {
  /**
   * ID of the student associated with the lesson session.
   *
   * @generated from field: int64 student_id = 1;
   */
  studentId = protoInt64.zero;

  /**
   * Section of the student.
   *
   * @generated from field: int32 assessment_id = 2;
   */
  assessmentId = 0;

  /**
   * Status of the lesson session (e.g., in progress, completed, etc.).
   *
   * @generated from field: geneo.lms.db.TaskStudentAttemptStatusEnum session_status = 3;
   */
  sessionStatus = TaskStudentAttemptStatusEnum.TASK_STUDENT_STATUS_UNDEFINED;

  /**
   * Mode of the lesson session (e.g., online, offline, etc.).
   *
   * @generated from field: geneo.lms.db.AssessmentSessionModeEnum session_mode = 4;
   */
  sessionMode = AssessmentSessionModeEnum.ASSESSMENT_SESSION_MODE_UNDEFINED;

  constructor(data?: PartialMessage<CreateStudentAssessmentSessionRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.lms.assessment.student.apis.CreateStudentAssessmentSessionRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "student_id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 2, name: "assessment_id", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 3, name: "session_status", kind: "enum", T: proto3.getEnumType(TaskStudentAttemptStatusEnum) },
    { no: 4, name: "session_mode", kind: "enum", T: proto3.getEnumType(AssessmentSessionModeEnum) },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): CreateStudentAssessmentSessionRequest {
    return new CreateStudentAssessmentSessionRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): CreateStudentAssessmentSessionRequest {
    return new CreateStudentAssessmentSessionRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): CreateStudentAssessmentSessionRequest {
    return new CreateStudentAssessmentSessionRequest().fromJsonString(jsonString, options);
  }

  static equals(a: CreateStudentAssessmentSessionRequest | PlainMessage<CreateStudentAssessmentSessionRequest> | undefined, b: CreateStudentAssessmentSessionRequest | PlainMessage<CreateStudentAssessmentSessionRequest> | undefined): boolean {
    return proto3.util.equals(CreateStudentAssessmentSessionRequest, a, b);
  }
}

/**
 * @generated from message geneo.lms.assessment.student.apis.CreateStudentAssessmentSessionResponse
 */
export class CreateStudentAssessmentSessionResponse extends Message<CreateStudentAssessmentSessionResponse> {
  /**
   * @generated from field: int32 status = 1;
   */
  status = 0;

  /**
   * @generated from field: string code = 2;
   */
  code = "";

  /**
   * @generated from field: string message = 3;
   */
  message = "";

  /**
   * @generated from field: int32 session_id = 4;
   */
  sessionId = 0;

  /**
   * @generated from field: optional int32 attempt_number = 5;
   */
  attemptNumber?: number;

  constructor(data?: PartialMessage<CreateStudentAssessmentSessionResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.lms.assessment.student.apis.CreateStudentAssessmentSessionResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "status", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 2, name: "code", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "message", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "session_id", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 5, name: "attempt_number", kind: "scalar", T: 5 /* ScalarType.INT32 */, opt: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): CreateStudentAssessmentSessionResponse {
    return new CreateStudentAssessmentSessionResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): CreateStudentAssessmentSessionResponse {
    return new CreateStudentAssessmentSessionResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): CreateStudentAssessmentSessionResponse {
    return new CreateStudentAssessmentSessionResponse().fromJsonString(jsonString, options);
  }

  static equals(a: CreateStudentAssessmentSessionResponse | PlainMessage<CreateStudentAssessmentSessionResponse> | undefined, b: CreateStudentAssessmentSessionResponse | PlainMessage<CreateStudentAssessmentSessionResponse> | undefined): boolean {
    return proto3.util.equals(CreateStudentAssessmentSessionResponse, a, b);
  }
}

/**
 * @generated from message geneo.lms.assessment.student.apis.SubmitAssessmentResponseSubmitRequest
 */
export class SubmitAssessmentResponseSubmitRequest extends Message<SubmitAssessmentResponseSubmitRequest> {
  /**
   * @generated from field: int64 student_id = 1;
   */
  studentId = protoInt64.zero;

  /**
   * @generated from field: int32 assessment_id = 2;
   */
  assessmentId = 0;

  /**
   * @generated from field: geneo.lms.db.TaskStudentAttemptStatusEnum assessment_attempt_status = 3;
   */
  assessmentAttemptStatus = TaskStudentAttemptStatusEnum.TASK_STUDENT_STATUS_UNDEFINED;

  /**
   * @generated from field: repeated geneo.lms.db.StudentAssessmentResponse responses = 4;
   */
  responses: StudentAssessmentResponse[] = [];

  /**
   * @generated from field: google.protobuf.Timestamp timestamp = 5;
   */
  timestamp?: Timestamp;

  /**
   * @generated from field: int32 session_id = 6;
   */
  sessionId = 0;

  /**
   * @generated from field: repeated geneo.lms.hw.student.apis.UploadFileResponse user_uploads = 7;
   */
  userUploads: UploadFileResponse[] = [];

  /**
   * @generated from field: geneo.lms.db.TaskEnum task_type = 8;
   */
  taskType = TaskEnum.TASK_UNDEFINED;

  /**
   * @generated from field: optional float attempt_spent_time_in_secs = 9;
   */
  attemptSpentTimeInSecs?: number;

  constructor(data?: PartialMessage<SubmitAssessmentResponseSubmitRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.lms.assessment.student.apis.SubmitAssessmentResponseSubmitRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "student_id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 2, name: "assessment_id", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 3, name: "assessment_attempt_status", kind: "enum", T: proto3.getEnumType(TaskStudentAttemptStatusEnum) },
    { no: 4, name: "responses", kind: "message", T: StudentAssessmentResponse, repeated: true },
    { no: 5, name: "timestamp", kind: "message", T: Timestamp },
    { no: 6, name: "session_id", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 7, name: "user_uploads", kind: "message", T: UploadFileResponse, repeated: true },
    { no: 8, name: "task_type", kind: "enum", T: proto3.getEnumType(TaskEnum) },
    { no: 9, name: "attempt_spent_time_in_secs", kind: "scalar", T: 2 /* ScalarType.FLOAT */, opt: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): SubmitAssessmentResponseSubmitRequest {
    return new SubmitAssessmentResponseSubmitRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): SubmitAssessmentResponseSubmitRequest {
    return new SubmitAssessmentResponseSubmitRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): SubmitAssessmentResponseSubmitRequest {
    return new SubmitAssessmentResponseSubmitRequest().fromJsonString(jsonString, options);
  }

  static equals(a: SubmitAssessmentResponseSubmitRequest | PlainMessage<SubmitAssessmentResponseSubmitRequest> | undefined, b: SubmitAssessmentResponseSubmitRequest | PlainMessage<SubmitAssessmentResponseSubmitRequest> | undefined): boolean {
    return proto3.util.equals(SubmitAssessmentResponseSubmitRequest, a, b);
  }
}

/**
 * @generated from message geneo.lms.assessment.student.apis.SubmitAssessmentResponseSubmitResponse
 */
export class SubmitAssessmentResponseSubmitResponse extends Message<SubmitAssessmentResponseSubmitResponse> {
  /**
   * @generated from field: int32 status = 1;
   */
  status = 0;

  /**
   * @generated from field: string code = 2;
   */
  code = "";

  /**
   * @generated from field: string message = 3;
   */
  message = "";

  /**
   * @generated from field: optional geneo.lms.assessment.student.apis.StudentAssessAttemptResult attempt_result = 4;
   */
  attemptResult?: StudentAssessAttemptResult;

  constructor(data?: PartialMessage<SubmitAssessmentResponseSubmitResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.lms.assessment.student.apis.SubmitAssessmentResponseSubmitResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "status", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 2, name: "code", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "message", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "attempt_result", kind: "message", T: StudentAssessAttemptResult, opt: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): SubmitAssessmentResponseSubmitResponse {
    return new SubmitAssessmentResponseSubmitResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): SubmitAssessmentResponseSubmitResponse {
    return new SubmitAssessmentResponseSubmitResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): SubmitAssessmentResponseSubmitResponse {
    return new SubmitAssessmentResponseSubmitResponse().fromJsonString(jsonString, options);
  }

  static equals(a: SubmitAssessmentResponseSubmitResponse | PlainMessage<SubmitAssessmentResponseSubmitResponse> | undefined, b: SubmitAssessmentResponseSubmitResponse | PlainMessage<SubmitAssessmentResponseSubmitResponse> | undefined): boolean {
    return proto3.util.equals(SubmitAssessmentResponseSubmitResponse, a, b);
  }
}

/**
 * @generated from message geneo.lms.assessment.student.apis.StudentAssessAttemptResult
 */
export class StudentAssessAttemptResult extends Message<StudentAssessAttemptResult> {
  /**
   * @generated from field: float response_score = 1;
   */
  responseScore = 0;

  /**
   * @generated from field: float max_score = 2;
   */
  maxScore = 0;

  constructor(data?: PartialMessage<StudentAssessAttemptResult>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.lms.assessment.student.apis.StudentAssessAttemptResult";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "response_score", kind: "scalar", T: 2 /* ScalarType.FLOAT */ },
    { no: 2, name: "max_score", kind: "scalar", T: 2 /* ScalarType.FLOAT */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): StudentAssessAttemptResult {
    return new StudentAssessAttemptResult().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): StudentAssessAttemptResult {
    return new StudentAssessAttemptResult().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): StudentAssessAttemptResult {
    return new StudentAssessAttemptResult().fromJsonString(jsonString, options);
  }

  static equals(a: StudentAssessAttemptResult | PlainMessage<StudentAssessAttemptResult> | undefined, b: StudentAssessAttemptResult | PlainMessage<StudentAssessAttemptResult> | undefined): boolean {
    return proto3.util.equals(StudentAssessAttemptResult, a, b);
  }
}

/**
 * @generated from message geneo.lms.assessment.student.apis.FetchStudentAssessmentResponseFetchRequest
 */
export class FetchStudentAssessmentResponseFetchRequest extends Message<FetchStudentAssessmentResponseFetchRequest> {
  /**
   * @generated from field: int64 student_id = 1;
   */
  studentId = protoInt64.zero;

  /**
   * @generated from field: int32 assessment_id = 2;
   */
  assessmentId = 0;

  constructor(data?: PartialMessage<FetchStudentAssessmentResponseFetchRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.lms.assessment.student.apis.FetchStudentAssessmentResponseFetchRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "student_id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 2, name: "assessment_id", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): FetchStudentAssessmentResponseFetchRequest {
    return new FetchStudentAssessmentResponseFetchRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): FetchStudentAssessmentResponseFetchRequest {
    return new FetchStudentAssessmentResponseFetchRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): FetchStudentAssessmentResponseFetchRequest {
    return new FetchStudentAssessmentResponseFetchRequest().fromJsonString(jsonString, options);
  }

  static equals(a: FetchStudentAssessmentResponseFetchRequest | PlainMessage<FetchStudentAssessmentResponseFetchRequest> | undefined, b: FetchStudentAssessmentResponseFetchRequest | PlainMessage<FetchStudentAssessmentResponseFetchRequest> | undefined): boolean {
    return proto3.util.equals(FetchStudentAssessmentResponseFetchRequest, a, b);
  }
}

/**
 * @generated from message geneo.lms.assessment.student.apis.FetchStudentAssessmentResponseFetchResponse
 */
export class FetchStudentAssessmentResponseFetchResponse extends Message<FetchStudentAssessmentResponseFetchResponse> {
  /**
   * @generated from field: int32 status = 1;
   */
  status = 0;

  /**
   * @generated from field: string code = 2;
   */
  code = "";

  /**
   * @generated from field: string message = 3;
   */
  message = "";

  /**
   * @generated from field: geneo.lms.assessment.student.apis.AssessmentStudentResponse data = 4;
   */
  data?: AssessmentStudentResponse;

  constructor(data?: PartialMessage<FetchStudentAssessmentResponseFetchResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.lms.assessment.student.apis.FetchStudentAssessmentResponseFetchResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "status", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 2, name: "code", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "message", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "data", kind: "message", T: AssessmentStudentResponse },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): FetchStudentAssessmentResponseFetchResponse {
    return new FetchStudentAssessmentResponseFetchResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): FetchStudentAssessmentResponseFetchResponse {
    return new FetchStudentAssessmentResponseFetchResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): FetchStudentAssessmentResponseFetchResponse {
    return new FetchStudentAssessmentResponseFetchResponse().fromJsonString(jsonString, options);
  }

  static equals(a: FetchStudentAssessmentResponseFetchResponse | PlainMessage<FetchStudentAssessmentResponseFetchResponse> | undefined, b: FetchStudentAssessmentResponseFetchResponse | PlainMessage<FetchStudentAssessmentResponseFetchResponse> | undefined): boolean {
    return proto3.util.equals(FetchStudentAssessmentResponseFetchResponse, a, b);
  }
}

/**
 * @generated from message geneo.lms.assessment.student.apis.AssessmentStudentResponse
 */
export class AssessmentStudentResponse extends Message<AssessmentStudentResponse> {
  /**
   * ID of the homework
   *
   * @generated from field: int32 assessment_id = 1;
   */
  assessmentId = 0;

  /**
   * ID of the student
   *
   * @generated from field: int64 student_id = 2;
   */
  studentId = protoInt64.zero;

  /**
   * Date when the task was commenced
   *
   * @generated from field: google.protobuf.Timestamp task_commence_date = 3;
   */
  taskCommenceDate?: Timestamp;

  /**
   * Target date for task completion
   *
   * @generated from field: google.protobuf.Timestamp task_target_date = 4;
   */
  taskTargetDate?: Timestamp;

  /**
   * Date when the task was completed
   *
   * @generated from field: google.protobuf.Timestamp task_completed_date = 5;
   */
  taskCompletedDate?: Timestamp;

  /**
   * Active status of the assessment
   *
   * @generated from field: geneo.lms.db.TaskActiveStatusEnum assessment_active_status = 6;
   */
  assessmentActiveStatus = TaskActiveStatusEnum.TASK_ACTIVE_STATUS_UNDEFINED;

  /**
   * Attempt status of the assessment
   *
   * @generated from field: geneo.lms.db.TaskStudentAttemptStatusEnum assessment_attempt_status = 7;
   */
  assessmentAttemptStatus = TaskStudentAttemptStatusEnum.TASK_STUDENT_STATUS_UNDEFINED;

  /**
   * Evaluation status of the homework
   *
   * @generated from field: geneo.lms.db.TaskEvaluationStatusEnum assessment_evaluation_status = 8;
   */
  assessmentEvaluationStatus = TaskEvaluationStatusEnum.TASK_EVALUATION_STATUS_UNDEFINED;

  /**
   * @generated from field: optional geneo.lms.db.AssessmentEnum assessment_type = 9;
   */
  assessmentType?: AssessmentEnum;

  /**
   * Metrics associated with the homework response
   *
   * @generated from field: geneo.lms.db.MetricsModel metrics = 10;
   */
  metrics?: MetricsModel;

  /**
   * List of student's responses to the homework questions
   *
   * @generated from field: repeated geneo.lms.db.StudentAssessmentResponse responses = 11;
   */
  responses: StudentAssessmentResponse[] = [];

  /**
   * Creation timestamp
   *
   * @generated from field: google.protobuf.Timestamp created_on = 12;
   */
  createdOn?: Timestamp;

  /**
   * Last modification timestamp
   *
   * @generated from field: optional google.protobuf.Timestamp modified_on = 13;
   */
  modifiedOn?: Timestamp;

  /**
   * @generated from field: repeated geneo.lms.db.CloudUpload user_uploads = 14;
   */
  userUploads: CloudUpload[] = [];

  /**
   * @generated from field: optional float attempt_spent_time_in_secs = 15;
   */
  attemptSpentTimeInSecs?: number;

  constructor(data?: PartialMessage<AssessmentStudentResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.lms.assessment.student.apis.AssessmentStudentResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "assessment_id", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 2, name: "student_id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 3, name: "task_commence_date", kind: "message", T: Timestamp },
    { no: 4, name: "task_target_date", kind: "message", T: Timestamp },
    { no: 5, name: "task_completed_date", kind: "message", T: Timestamp },
    { no: 6, name: "assessment_active_status", kind: "enum", T: proto3.getEnumType(TaskActiveStatusEnum) },
    { no: 7, name: "assessment_attempt_status", kind: "enum", T: proto3.getEnumType(TaskStudentAttemptStatusEnum) },
    { no: 8, name: "assessment_evaluation_status", kind: "enum", T: proto3.getEnumType(TaskEvaluationStatusEnum) },
    { no: 9, name: "assessment_type", kind: "enum", T: proto3.getEnumType(AssessmentEnum), opt: true },
    { no: 10, name: "metrics", kind: "message", T: MetricsModel },
    { no: 11, name: "responses", kind: "message", T: StudentAssessmentResponse, repeated: true },
    { no: 12, name: "created_on", kind: "message", T: Timestamp },
    { no: 13, name: "modified_on", kind: "message", T: Timestamp, opt: true },
    { no: 14, name: "user_uploads", kind: "message", T: CloudUpload, repeated: true },
    { no: 15, name: "attempt_spent_time_in_secs", kind: "scalar", T: 2 /* ScalarType.FLOAT */, opt: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): AssessmentStudentResponse {
    return new AssessmentStudentResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): AssessmentStudentResponse {
    return new AssessmentStudentResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): AssessmentStudentResponse {
    return new AssessmentStudentResponse().fromJsonString(jsonString, options);
  }

  static equals(a: AssessmentStudentResponse | PlainMessage<AssessmentStudentResponse> | undefined, b: AssessmentStudentResponse | PlainMessage<AssessmentStudentResponse> | undefined): boolean {
    return proto3.util.equals(AssessmentStudentResponse, a, b);
  }
}

