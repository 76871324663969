import { Dialog, IconButton, Typography, useMediaQuery } from '@mui/material';
import { Box } from '@mui/system';
import { pxToRem, pxTovW } from '../../../commonUtils/resizeUtils';
import ImageWrapper from '../../elements/ImageWrapper';
import { getMediaBasePath } from '../../../commonUtils/images';
import PrimaryButton from '../../elements/PrimaryButton';
import { StudentAssessmentTask } from '@protos/learning_management/lms.assessment.student.apis_pb';
import { useState } from 'react';
import { theme } from '../../../theme/themeProvider';
import { ContentListAccordian } from '../ContentListAccordian';
import { IStyles } from '../../../commonUtils/styleUtils';
import { AssessmentTask } from '@protos/learning_management/lms.assessment.common.apis_pb';
const styles: IStyles = {
  image: {
    width: '100%',
    objectFit: 'cover',
    borderRadius: { xs: pxToRem(10), md: pxTovW(10) },
  },
};
interface IProps {
  open: boolean;

  handleClose?: () => void;

  assessment?: StudentAssessmentTask | AssessmentTask;
}
export const AssessmentTopicPopup = (props: IProps) => {
  const { handleClose, open, assessment } = props;
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  if (!assessment) {
    return null;
  }
  console.log({ assessment });

  return (
    <Dialog
      open={open}
      onClose={handleClose && handleClose}
      maxWidth="md"
      sx={{
        '& .MuiDialog-paper': {
          // position: 'absolute',

          // top: '10%',

          borderRadius: '20px',

          background: 'transparent',

          border: '0px solid transparent',

          boxShadow: 'none',

          width: '100%',

          maxWidth: { xs: '100%', md: pxTovW(1400), lg: pxTovW(1020) },

          // height: '495px',

          '@media (max-width:600px)': {
            width: '100%', // Adjust width for extra-large screens (lg and above)
          },
        },
      }}
    >
      <Box
        sx={{
          display: 'flex',

          alignItems: 'flex-start',

          flexDirection: { xs: 'column', md: 'row' },

          gap: { xs: pxToRem(10), md: pxTovW(23) },
        }}
      >
        <Box
          sx={{
            borderRadius: '20px',

            background: 'white',

            padding: {
              xs: `${pxToRem(17)} ${pxToRem(20)}`,

              md: `${pxTovW(35)} ${pxTovW(33)}`,

              lg: `${pxTovW(35)} ${pxTovW(33)}`,
            },

            width: '100%',

            height: 'max-content',

            maxHeight: { xs: pxToRem(480), md: pxTovW(881), lg: pxTovW(681) },

            overflowY: 'auto',

            maxWidth: {
              xs: `calc(100% - ${pxToRem(40)})`,

              md: `calc(100% - ${pxTovW(66)})`,

              lg: `calc(100% - ${pxTovW(66)})`,
            },

            display: 'flex',

            flexDirection: 'column',

            gap: { xs: pxToRem(24), md: pxToRem(31) },

            alignItems: 'center',

            justifyContent: 'space-between',

            marginX: 'auto',
          }}
        >
          <Box
            sx={{
              display: 'flex',

              flexDirection: 'column',
              width: { xs: '100%', md: 'unset' },
              gap: { xs: pxToRem(10), md: pxTovW(31) },
            }}
          >
            <Box
              sx={{
                display: 'flex',

                width: '100%',

                gap: { xs: pxToRem(12), md: pxTovW(14) },
              }}
            >
              <Box
                sx={{
                  display: 'flex',

                  flexDirection: 'column',

                  justifyContent: 'space-around',

                  width: { xs: pxToRem(56), md: pxTovW(221), lg: pxTovW(100) },

                  minWidth: {
                    xs: pxToRem(56),

                    md: pxTovW(221),

                    lg: pxTovW(100),
                  },

                  height: { xs: pxToRem(56), md: 'auto', lg: pxTovW(100) },

                  boxSizing: 'border-box',

                  borderRadius: { xs: pxToRem(10) },

                  backgroundColor: '#E0DFDE',
                }}
              >
                <ImageWrapper
                  name="chapter1"
                  type="png"
                  parentFolder="tempAssets"
                  styles={{
                    ...styles.image,

                    height: '100%',
                  }}
                  path={getMediaBasePath(
                    assessment.assessmentPosterImgUrl,

                    'processedMediaBucket'
                  )}
                />
              </Box>

              <Typography
                variant={isMobile ? 'h2' : 'h1'}
                fontFamily={'lato'}
                fontWeight={'bold'}
                sx={{
                  textOverflow: 'ellipsis',
                  WebkitLineClamp: 3,
                  WebkitBoxOrient: 'vertical',
                  overflow: 'hidden',
                  whiteSpace: 'nowrap',
                  maxHeight: pxToRem(40),
                  width: { xs: '90vw', md: pxTovW(700) },
                }}
              >
                {assessment.assessmentTitle}
              </Typography>
            </Box>

            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                gap: { xs: pxToRem(10), md: pxTovW(15) },
                width: '100%',
              }}
            >
              {assessment.taskMetaInfo?.chapterInfo?.chapterTopicsInfo.map(
                (chapter) => (
                  <ContentListAccordian
                    // open={openList}
                    HeaderContent={
                      <Box
                        onClick={(ev?: React.MouseEvent) => {
                          ev?.stopPropagation();
                        }}
                        sx={{
                          display: 'flex',

                          flexDirection: { xs: 'column', md: 'row' },

                          justifyContent: 'space-between',

                          width: {
                            xs: '100%',

                            md: pxTovW(880),

                            lg: pxTovW(680),
                          },
                        }}
                      >
                        <Typography
                          variant={isMobile ? 'smallText' : 'bodyText'}
                          fontWeight={'medium'}
                          fontFamily={'poppins'}
                        >
                          {chapter.name}
                        </Typography>

                        <Box
                          sx={{
                            display: 'flex',

                            justifyContent: 'center',

                            alignItems: 'center',

                            background: '#007CDC',

                            width: 'max-content',

                            padding: {
                              xs: `${pxToRem(2)} ${pxToRem(4)} ${pxToRem(2)}`,

                              md: `${pxTovW(3)} ${pxTovW(13)} ${pxTovW(4)}`,
                            },

                            borderRadius: {
                              xs: pxToRem(10),

                              md: pxTovW(20),

                              lg: pxTovW(15),
                            },
                          }}
                        >
                          <Typography
                            variant={isMobile ? 'smallestText' : 'h5'}
                            color={'common.white'}
                            fontWeight={'medium'}
                            fontFamily={'poppins'}
                          >
                            Total Topics: {chapter.topicInfo.length}
                          </Typography>
                        </Box>
                      </Box>
                    }
                    variant="large"
                    chapterData={[]}
                    // handleExpandmore={(ev?: React.MouseEvent) => {
                    //   ev?.stopPropagation();

                    //   setOpenList(true);
                    // }}
                    // handleExpandless={(ev?: React.MouseEvent) => {
                    //   ev?.stopPropagation();

                    //   setOpenList(false);
                    // }}
                    expandDisabled={false}
                    ListItemArray={chapter.topicInfo.map((e) => e.name)}
                  />
                )
              )}
            </Box>
          </Box>

          <Box
            sx={{
              width: { xs: pxToRem(230), md: pxTovW(650), lg: pxTovW(354) },
            }}
          >
            <PrimaryButton fullWidth onClick={handleClose}>
              OK
            </PrimaryButton>
          </Box>
        </Box>

        <IconButton
          color="inherit"
          onClick={handleClose}
          sx={{
            marginX: 'auto',
          }}
        >
          <ImageWrapper
            name="close-yellow"
            type="png"
            parentFolder="icons"
            styles={{
              top: '10%',

              width: { xs: pxToRem(33), md: pxTovW(65) }, // Small white "X"

              height: { xs: pxToRem(33), md: pxTovW(65) },
            }}
          />
        </IconButton>
      </Box>
    </Dialog>
  );
};
