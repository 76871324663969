// @generated by protoc-gen-es v1.2.1 with parameter "target=ts"
// @generated from file src/protos/user_management/ums.login.sso.apis.proto (package geneo.ums.sso.login.apis, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import type { BinaryReadOptions, FieldList, JsonReadOptions, JsonValue, PartialMessage, PlainMessage } from "@bufbuild/protobuf";
import { Message, proto3, protoInt64 } from "@bufbuild/protobuf";
import { ProfileRolesEnum } from "@protos/user_management/ums.db_pb";

/**
 * @generated from message geneo.ums.sso.login.apis.ValidateUserSsoLoginRequest
 */
export class ValidateUserSsoLoginRequest extends Message<ValidateUserSsoLoginRequest> {
  /**
   * @generated from field: string request_origin = 1;
   */
  requestOrigin = "";

  /**
   * @generated from field: string code = 2;
   */
  code = "";

  /**
   * @generated from field: geneo.ums.db.ProfileRolesEnum role = 3;
   */
  role = ProfileRolesEnum.PROFILE_ROLE_UNDEFINED;

  constructor(data?: PartialMessage<ValidateUserSsoLoginRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.ums.sso.login.apis.ValidateUserSsoLoginRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "request_origin", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "code", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "role", kind: "enum", T: proto3.getEnumType(ProfileRolesEnum) },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ValidateUserSsoLoginRequest {
    return new ValidateUserSsoLoginRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ValidateUserSsoLoginRequest {
    return new ValidateUserSsoLoginRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ValidateUserSsoLoginRequest {
    return new ValidateUserSsoLoginRequest().fromJsonString(jsonString, options);
  }

  static equals(a: ValidateUserSsoLoginRequest | PlainMessage<ValidateUserSsoLoginRequest> | undefined, b: ValidateUserSsoLoginRequest | PlainMessage<ValidateUserSsoLoginRequest> | undefined): boolean {
    return proto3.util.equals(ValidateUserSsoLoginRequest, a, b);
  }
}

/**
 * @generated from message geneo.ums.sso.login.apis.ValidateUserSsoLoginV2Request
 */
export class ValidateUserSsoLoginV2Request extends Message<ValidateUserSsoLoginV2Request> {
  /**
   * @generated from field: string request_origin = 1;
   */
  requestOrigin = "";

  /**
   * @generated from field: string unique_id = 2;
   */
  uniqueId = "";

  /**
   * @generated from field: string token = 3;
   */
  token = "";

  /**
   * @generated from field: geneo.ums.db.ProfileRolesEnum role = 4;
   */
  role = ProfileRolesEnum.PROFILE_ROLE_UNDEFINED;

  constructor(data?: PartialMessage<ValidateUserSsoLoginV2Request>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.ums.sso.login.apis.ValidateUserSsoLoginV2Request";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "request_origin", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "unique_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "token", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "role", kind: "enum", T: proto3.getEnumType(ProfileRolesEnum) },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ValidateUserSsoLoginV2Request {
    return new ValidateUserSsoLoginV2Request().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ValidateUserSsoLoginV2Request {
    return new ValidateUserSsoLoginV2Request().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ValidateUserSsoLoginV2Request {
    return new ValidateUserSsoLoginV2Request().fromJsonString(jsonString, options);
  }

  static equals(a: ValidateUserSsoLoginV2Request | PlainMessage<ValidateUserSsoLoginV2Request> | undefined, b: ValidateUserSsoLoginV2Request | PlainMessage<ValidateUserSsoLoginV2Request> | undefined): boolean {
    return proto3.util.equals(ValidateUserSsoLoginV2Request, a, b);
  }
}

/**
 * @generated from message geneo.ums.sso.login.apis.ValidateUserSsoLoginResponse
 */
export class ValidateUserSsoLoginResponse extends Message<ValidateUserSsoLoginResponse> {
  /**
   * @generated from field: int32 status = 1;
   */
  status = 0;

  /**
   * @generated from field: string message = 2;
   */
  message = "";

  /**
   * @generated from field: string sso_token = 3;
   */
  ssoToken = "";

  /**
   * @generated from field: geneo.ums.sso.login.apis.UserInfo user_info = 4;
   */
  userInfo?: UserInfo;

  /**
   * @generated from field: string sso_redirection_url = 5;
   */
  ssoRedirectionUrl = "";

  constructor(data?: PartialMessage<ValidateUserSsoLoginResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.ums.sso.login.apis.ValidateUserSsoLoginResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "status", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 2, name: "message", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "sso_token", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "user_info", kind: "message", T: UserInfo },
    { no: 5, name: "sso_redirection_url", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ValidateUserSsoLoginResponse {
    return new ValidateUserSsoLoginResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ValidateUserSsoLoginResponse {
    return new ValidateUserSsoLoginResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ValidateUserSsoLoginResponse {
    return new ValidateUserSsoLoginResponse().fromJsonString(jsonString, options);
  }

  static equals(a: ValidateUserSsoLoginResponse | PlainMessage<ValidateUserSsoLoginResponse> | undefined, b: ValidateUserSsoLoginResponse | PlainMessage<ValidateUserSsoLoginResponse> | undefined): boolean {
    return proto3.util.equals(ValidateUserSsoLoginResponse, a, b);
  }
}

/**
 * @generated from message geneo.ums.sso.login.apis.ssoLoginSessionRequest
 */
export class ssoLoginSessionRequest extends Message<ssoLoginSessionRequest> {
  /**
   * @generated from field: int64 profileId = 1;
   */
  profileId = protoInt64.zero;

  constructor(data?: PartialMessage<ssoLoginSessionRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.ums.sso.login.apis.ssoLoginSessionRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "profileId", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ssoLoginSessionRequest {
    return new ssoLoginSessionRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ssoLoginSessionRequest {
    return new ssoLoginSessionRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ssoLoginSessionRequest {
    return new ssoLoginSessionRequest().fromJsonString(jsonString, options);
  }

  static equals(a: ssoLoginSessionRequest | PlainMessage<ssoLoginSessionRequest> | undefined, b: ssoLoginSessionRequest | PlainMessage<ssoLoginSessionRequest> | undefined): boolean {
    return proto3.util.equals(ssoLoginSessionRequest, a, b);
  }
}

/**
 * @generated from message geneo.ums.sso.login.apis.ssoLoginSessionResponse
 */
export class ssoLoginSessionResponse extends Message<ssoLoginSessionResponse> {
  /**
   * @generated from field: int32 status = 1;
   */
  status = 0;

  /**
   * @generated from field: string message = 2;
   */
  message = "";

  /**
   * @generated from field: string token = 3;
   */
  token = "";

  /**
   * @generated from field: int32 active_session_id = 4;
   */
  activeSessionId = 0;

  /**
   * @generated from field: int64 profile_login_details_id = 5;
   */
  profileLoginDetailsId = protoInt64.zero;

  constructor(data?: PartialMessage<ssoLoginSessionResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.ums.sso.login.apis.ssoLoginSessionResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "status", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 2, name: "message", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "token", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "active_session_id", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 5, name: "profile_login_details_id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ssoLoginSessionResponse {
    return new ssoLoginSessionResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ssoLoginSessionResponse {
    return new ssoLoginSessionResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ssoLoginSessionResponse {
    return new ssoLoginSessionResponse().fromJsonString(jsonString, options);
  }

  static equals(a: ssoLoginSessionResponse | PlainMessage<ssoLoginSessionResponse> | undefined, b: ssoLoginSessionResponse | PlainMessage<ssoLoginSessionResponse> | undefined): boolean {
    return proto3.util.equals(ssoLoginSessionResponse, a, b);
  }
}

/**
 * @generated from message geneo.ums.sso.login.apis.UserInfo
 */
export class UserInfo extends Message<UserInfo> {
  /**
   * @generated from field: string first_name = 1;
   */
  firstName = "";

  /**
   * @generated from field: string last_name = 2;
   */
  lastName = "";

  /**
   * @generated from field: string user_name = 3;
   */
  userName = "";

  /**
   * @generated from field: string phone_number = 4;
   */
  phoneNumber = "";

  /**
   * @generated from field: string email = 5;
   */
  email = "";

  /**
   * @generated from field: bool is_profile_verified = 6;
   */
  isProfileVerified = false;

  /**
   * @generated from field: bool is_password_updated = 7;
   */
  isPasswordUpdated = false;

  /**
   * @generated from field: int32 is_active = 8;
   */
  isActive = 0;

  /**
   * @generated from field: int32 email_verify = 9;
   */
  emailVerify = 0;

  /**
   * @generated from field: int32 phone_verify = 10;
   */
  phoneVerify = 0;

  /**
   * @generated from field: int64 profile_id = 11;
   */
  profileId = protoInt64.zero;

  constructor(data?: PartialMessage<UserInfo>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.ums.sso.login.apis.UserInfo";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "first_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "last_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "user_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "phone_number", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "email", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 6, name: "is_profile_verified", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 7, name: "is_password_updated", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 8, name: "is_active", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 9, name: "email_verify", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 10, name: "phone_verify", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 11, name: "profile_id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): UserInfo {
    return new UserInfo().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): UserInfo {
    return new UserInfo().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): UserInfo {
    return new UserInfo().fromJsonString(jsonString, options);
  }

  static equals(a: UserInfo | PlainMessage<UserInfo> | undefined, b: UserInfo | PlainMessage<UserInfo> | undefined): boolean {
    return proto3.util.equals(UserInfo, a, b);
  }
}

/**
 * @generated from message geneo.ums.sso.login.apis.CreateSsoOrganizationRequest
 */
export class CreateSsoOrganizationRequest extends Message<CreateSsoOrganizationRequest> {
  /**
   * @generated from field: string sso_provider = 1;
   */
  ssoProvider = "";

  /**
   * @generated from field: string organization = 2;
   */
  organization = "";

  /**
   * @generated from field: string organization_key = 3;
   */
  organizationKey = "";

  constructor(data?: PartialMessage<CreateSsoOrganizationRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.ums.sso.login.apis.CreateSsoOrganizationRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "sso_provider", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "organization", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "organization_key", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): CreateSsoOrganizationRequest {
    return new CreateSsoOrganizationRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): CreateSsoOrganizationRequest {
    return new CreateSsoOrganizationRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): CreateSsoOrganizationRequest {
    return new CreateSsoOrganizationRequest().fromJsonString(jsonString, options);
  }

  static equals(a: CreateSsoOrganizationRequest | PlainMessage<CreateSsoOrganizationRequest> | undefined, b: CreateSsoOrganizationRequest | PlainMessage<CreateSsoOrganizationRequest> | undefined): boolean {
    return proto3.util.equals(CreateSsoOrganizationRequest, a, b);
  }
}

/**
 * @generated from message geneo.ums.sso.login.apis.CreateSsoOrganizationResponse
 */
export class CreateSsoOrganizationResponse extends Message<CreateSsoOrganizationResponse> {
  /**
   * @generated from field: int32 status = 1;
   */
  status = 0;

  /**
   * @generated from field: string code = 2;
   */
  code = "";

  /**
   * @generated from field: string message = 3;
   */
  message = "";

  /**
   * @generated from field: geneo.ums.sso.login.apis.ClassSectionUploadStatus classSectionUploadStatus = 4;
   */
  classSectionUploadStatus?: ClassSectionUploadStatus;

  constructor(data?: PartialMessage<CreateSsoOrganizationResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.ums.sso.login.apis.CreateSsoOrganizationResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "status", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 2, name: "code", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "message", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "classSectionUploadStatus", kind: "message", T: ClassSectionUploadStatus },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): CreateSsoOrganizationResponse {
    return new CreateSsoOrganizationResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): CreateSsoOrganizationResponse {
    return new CreateSsoOrganizationResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): CreateSsoOrganizationResponse {
    return new CreateSsoOrganizationResponse().fromJsonString(jsonString, options);
  }

  static equals(a: CreateSsoOrganizationResponse | PlainMessage<CreateSsoOrganizationResponse> | undefined, b: CreateSsoOrganizationResponse | PlainMessage<CreateSsoOrganizationResponse> | undefined): boolean {
    return proto3.util.equals(CreateSsoOrganizationResponse, a, b);
  }
}

/**
 * {
 *   "sectionID": 2356,
 *   "section": "B",
 *   "classID": 1441,
 *   "className": "b.com/satish - I Year - 2 Semester",
 *   "branchID": 95,
 *   "branchName": "Swarnprastha Public school",
 *   "branchGUID": "412C473C-7AA6-4B79-B093-DBE7B6BDDDE9"
 * }
 *
 * @generated from message geneo.ums.sso.login.apis.ClassSectionUploadStatus
 */
export class ClassSectionUploadStatus extends Message<ClassSectionUploadStatus> {
  /**
   * @generated from field: repeated geneo.ums.sso.login.apis.ClassSectionData invalidBranchClassSections = 1;
   */
  invalidBranchClassSections: ClassSectionData[] = [];

  /**
   * @generated from field: repeated geneo.ums.sso.login.apis.ClassSectionData unsupportedClassSections = 2;
   */
  unsupportedClassSections: ClassSectionData[] = [];

  /**
   * @generated from field: repeated geneo.ums.sso.login.apis.ClassSectionData existingClassSections = 3;
   */
  existingClassSections: ClassSectionData[] = [];

  /**
   * @generated from field: repeated geneo.ums.sso.login.apis.ClassSectionData uploadedClassSections = 4;
   */
  uploadedClassSections: ClassSectionData[] = [];

  constructor(data?: PartialMessage<ClassSectionUploadStatus>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.ums.sso.login.apis.ClassSectionUploadStatus";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "invalidBranchClassSections", kind: "message", T: ClassSectionData, repeated: true },
    { no: 2, name: "unsupportedClassSections", kind: "message", T: ClassSectionData, repeated: true },
    { no: 3, name: "existingClassSections", kind: "message", T: ClassSectionData, repeated: true },
    { no: 4, name: "uploadedClassSections", kind: "message", T: ClassSectionData, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ClassSectionUploadStatus {
    return new ClassSectionUploadStatus().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ClassSectionUploadStatus {
    return new ClassSectionUploadStatus().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ClassSectionUploadStatus {
    return new ClassSectionUploadStatus().fromJsonString(jsonString, options);
  }

  static equals(a: ClassSectionUploadStatus | PlainMessage<ClassSectionUploadStatus> | undefined, b: ClassSectionUploadStatus | PlainMessage<ClassSectionUploadStatus> | undefined): boolean {
    return proto3.util.equals(ClassSectionUploadStatus, a, b);
  }
}

/**
 * @generated from message geneo.ums.sso.login.apis.ClassSectionData
 */
export class ClassSectionData extends Message<ClassSectionData> {
  /**
   * @generated from field: int32 sectionID = 1;
   */
  sectionID = 0;

  /**
   * @generated from field: string section = 2;
   */
  section = "";

  /**
   * @generated from field: int32 classID = 3;
   */
  classID = 0;

  /**
   * @generated from field: string className = 4;
   */
  className = "";

  /**
   * @generated from field: int32 branchID = 5;
   */
  branchID = 0;

  /**
   * @generated from field: string branchName = 6;
   */
  branchName = "";

  /**
   * @generated from field: string branchGUID = 7;
   */
  branchGUID = "";

  constructor(data?: PartialMessage<ClassSectionData>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.ums.sso.login.apis.ClassSectionData";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "sectionID", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 2, name: "section", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "classID", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 4, name: "className", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "branchID", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 6, name: "branchName", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 7, name: "branchGUID", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ClassSectionData {
    return new ClassSectionData().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ClassSectionData {
    return new ClassSectionData().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ClassSectionData {
    return new ClassSectionData().fromJsonString(jsonString, options);
  }

  static equals(a: ClassSectionData | PlainMessage<ClassSectionData> | undefined, b: ClassSectionData | PlainMessage<ClassSectionData> | undefined): boolean {
    return proto3.util.equals(ClassSectionData, a, b);
  }
}

/**
 * @generated from message geneo.ums.sso.login.apis.StudentOnboardingData
 */
export class StudentOnboardingData extends Message<StudentOnboardingData> {
  /**
   * @generated from field: string sub = 1;
   */
  sub = "";

  /**
   * @generated from field: string preferred_username = 2;
   */
  preferredUsername = "";

  /**
   * @generated from field: string className = 3;
   */
  className = "";

  /**
   * @generated from field: string section = 4;
   */
  section = "";

  /**
   * @generated from field: string studentEnrollmentCode = 5;
   */
  studentEnrollmentCode = "";

  /**
   * @generated from field: string fullName = 6;
   */
  fullName = "";

  /**
   * @generated from field: string branchName = 7;
   */
  branchName = "";

  /**
   * @generated from field: string organisationName = 8;
   */
  organisationName = "";

  /**
   * @generated from field: string role = 9;
   */
  role = "";

  /**
   * @generated from field: int32 classID = 10;
   */
  classID = 0;

  /**
   * @generated from field: int32 branchSectionID = 11;
   */
  branchSectionID = 0;

  /**
   * @generated from field: string branchGUID = 12;
   */
  branchGUID = "";

  /**
   * @generated from field: string organisationGUID = 13;
   */
  organisationGUID = "";

  constructor(data?: PartialMessage<StudentOnboardingData>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.ums.sso.login.apis.StudentOnboardingData";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "sub", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "preferred_username", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "className", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "section", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "studentEnrollmentCode", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 6, name: "fullName", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 7, name: "branchName", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 8, name: "organisationName", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 9, name: "role", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 10, name: "classID", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 11, name: "branchSectionID", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 12, name: "branchGUID", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 13, name: "organisationGUID", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): StudentOnboardingData {
    return new StudentOnboardingData().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): StudentOnboardingData {
    return new StudentOnboardingData().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): StudentOnboardingData {
    return new StudentOnboardingData().fromJsonString(jsonString, options);
  }

  static equals(a: StudentOnboardingData | PlainMessage<StudentOnboardingData> | undefined, b: StudentOnboardingData | PlainMessage<StudentOnboardingData> | undefined): boolean {
    return proto3.util.equals(StudentOnboardingData, a, b);
  }
}

/**
 * @generated from message geneo.ums.sso.login.apis.OnboardMcbStudentsRequest
 */
export class OnboardMcbStudentsRequest extends Message<OnboardMcbStudentsRequest> {
  /**
   * @generated from field: repeated geneo.ums.sso.login.apis.StudentOnboardingData students_data = 1;
   */
  studentsData: StudentOnboardingData[] = [];

  constructor(data?: PartialMessage<OnboardMcbStudentsRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.ums.sso.login.apis.OnboardMcbStudentsRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "students_data", kind: "message", T: StudentOnboardingData, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): OnboardMcbStudentsRequest {
    return new OnboardMcbStudentsRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): OnboardMcbStudentsRequest {
    return new OnboardMcbStudentsRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): OnboardMcbStudentsRequest {
    return new OnboardMcbStudentsRequest().fromJsonString(jsonString, options);
  }

  static equals(a: OnboardMcbStudentsRequest | PlainMessage<OnboardMcbStudentsRequest> | undefined, b: OnboardMcbStudentsRequest | PlainMessage<OnboardMcbStudentsRequest> | undefined): boolean {
    return proto3.util.equals(OnboardMcbStudentsRequest, a, b);
  }
}

/**
 * @generated from message geneo.ums.sso.login.apis.OnboardMcbStudentsResponse
 */
export class OnboardMcbStudentsResponse extends Message<OnboardMcbStudentsResponse> {
  /**
   * @generated from field: repeated string onboarded_students = 1;
   */
  onboardedStudents: string[] = [];

  /**
   * @generated from field: repeated string onboarding_failed_students = 2;
   */
  onboardingFailedStudents: string[] = [];

  /**
   * @generated from field: repeated string students_already_exists = 3;
   */
  studentsAlreadyExists: string[] = [];

  constructor(data?: PartialMessage<OnboardMcbStudentsResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.ums.sso.login.apis.OnboardMcbStudentsResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "onboarded_students", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 2, name: "onboarding_failed_students", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 3, name: "students_already_exists", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): OnboardMcbStudentsResponse {
    return new OnboardMcbStudentsResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): OnboardMcbStudentsResponse {
    return new OnboardMcbStudentsResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): OnboardMcbStudentsResponse {
    return new OnboardMcbStudentsResponse().fromJsonString(jsonString, options);
  }

  static equals(a: OnboardMcbStudentsResponse | PlainMessage<OnboardMcbStudentsResponse> | undefined, b: OnboardMcbStudentsResponse | PlainMessage<OnboardMcbStudentsResponse> | undefined): boolean {
    return proto3.util.equals(OnboardMcbStudentsResponse, a, b);
  }
}

