import { pxToRem, pxTovW } from '../../../commonUtils/resizeUtils';
import { IStyles } from '../../../commonUtils/styleUtils';
import { IconWrapper } from '../../elements/IconWrapper/Index';
import PrimaryButton from '../../elements/PrimaryButton';

import {
  Box,
  Modal,
  Radio,
  Slide,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { ProfileRejectionEnum } from '@protos/user_management/ums.db_pb';
import { ChangeEvent, Dispatch, SetStateAction } from 'react';
import ImageWrapper from '../../elements/ImageWrapper';
import { InstructionsPanel } from '../InstructionsPanel';

const styles: IStyles = {
  modal: {
    boxSizing: 'border-box',
    // pt: { xs: '0', md: pxTovW(300) },
    display: { xs: 'flex', md: 'flex' },
    alignItems: { xs: 'flex-end', md: 'center' },
  },
  root: {
    // border: '1px solid red',
    mt: { xs: '10vh', md: pxTovW(90) },
    backgroundColor: 'common.white',

    width: { xs: '95%', md: pxTovW(537) },
    height: { xs: 'max-content', md: 'max-content' },
    maxHeight: { xs: '60vh', md: '100vh' },
    margin: 'auto',
    p: {
      xs: `${pxToRem(50)} ${pxToRem(10)} ${pxToRem(15)}`,
      md: `${pxTovW(128)} ${pxTovW(45)} ${pxTovW(30)}`,
    },
    borderRadius: { xs: pxToRem(20), md: pxTovW(20) },
    position: 'relative',
    top: { md: '18%', lg: '0%' },
    // overflowY: 'scroll',
  },
  iconBox: {
    backgroundColor: 'common.white',
    p: { xs: pxToRem(20), md: pxTovW(28) },
    borderRadius: '50%',
    position: 'absolute',
    top: { xs: '-7%', md: '-11%' },
    left: { xs: '38%', md: '39%' },
  },
  managerIcon: {
    height: { xs: pxToRem(50), md: pxTovW(116) },
    width: { xs: pxToRem(50), md: pxTovW(116) },
    margin: 'auto',
    // backgroundColor: 'red',
  },
  close: {
    width: { xs: pxToRem(10), md: pxTovW(20) },
    height: { xs: pxToRem(10), md: pxTovW(20) },
    // alignItems: 'center',
    cursor: 'pointer',
    position: 'absolute',
    top: { xs: '5%', md: '7%' },
    // left: { xs: '38%', md: '36%' },
    right: { xs: '5%', md: '5%' },
  },
  headingText: {
    p: {
      xs: `${pxToRem(0)} ${pxToRem(10)}`,
      md: `${pxTovW(0)} ${pxTovW(20)}`,
    },
    mt: { xs: pxToRem(20), md: pxTovW(0) },
  },
  radioBox: {
    display: 'flex',
    flexDirection: { xs: 'row', md: 'row' },
    gap: { xs: pxToRem(20), md: pxTovW(50) },
    mt: { xs: pxToRem(14), md: pxTovW(14) },
    mb: { xs: pxToRem(40), md: pxTovW(20) },
    flexWrap: 'wrap',
  },
  radioDetails: {
    display: 'flex',
    alignItems: 'center',
    gap: { xs: pxToRem(7), md: pxTovW(7) },
  },
  radio: {
    color: '#CCE6FE',
    '& .MuiSvgIcon-root': {
      fontSize: { xs: pxToRem(15), md: pxTovW(30) },
    },
    '&.MuiRadio-root': {
      padding: '0',
    },
  },
  instructionsPanel: {
    // display: 'flex',
    // flexGrow: 1,
    // maxHeight: { xs: pxToRem(420), md: pxTovW(666) },

    height: { xs: pxToRem(250), md: pxTovW(380) },
  },
  reasonContainer: {
    backgroundColor: 'neutral.lightPink',
    border: '2px solid red',
    borderColor: 'neutral.crimson',
    borderRadius: { xs: pxToRem(15), md: pxTovW(15) },
    p: {
      xs: `${pxToRem(15)} ${pxToRem(0)}`,
      md: `${pxTovW(18)} ${pxTovW(0)}`,
    },
    mb: { xs: pxToRem(20), md: pxTovW(22) },
    // maxHeight: { xs: '15vh', md: '15vh' },
    // overflowY: 'scroll',
    textAlign: 'center',
  },

  notesListBox: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    maxHeight: { xs: '15vh', md: '15vh' },
    overflowY: 'auto',
    mb: { xs: pxToRem(40), md: pxTovW(45) },
    pl: { xs: pxToRem(0), md: pxTovW(25) },
  },
  checkProfileButtonBox: {
    width: { xs: pxToRem(150), md: pxTovW(375) },
    margin: 'auto',
  },
};

interface AdminRejectPopupProps {
  modalState: boolean;
  closeModalState: (arg: boolean) => void;
  profileActionHandler: () => void;
  isEditable?: boolean;
  title?: string;

  // data required for Editable
  radioValue?: string;
  setRadioValue?: Dispatch<SetStateAction<string>>;
  notesValue?: string | undefined;
  setNotesValue?: Dispatch<SetStateAction<string | undefined>>;
  reasonChoices?: string[];

  // data required for nonEditable
  rejectionReason?: ProfileRejectionEnum | undefined;
  reasonData?: string;
}
export const AdminRejectPopup = (props: AdminRejectPopupProps) => {
  const {
    modalState,
    closeModalState,
    profileActionHandler,
    isEditable,
    title,

    radioValue,
    setRadioValue,
    notesValue,
    setNotesValue,
    reasonChoices,

    rejectionReason,
    reasonData,
  } = props;

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  const controlRadioProps = (item: string) => ({
    name: 'color-radio-button',
    value: item,
    checked: radioValue === item,
    onChange: async (event: ChangeEvent<HTMLInputElement>) => {
      setRadioValue && setRadioValue(event.target.value);
    },
    inputProps: { 'aria-label': item },
  });

  return (
    <Modal
      open={modalState}
      onClose={() => closeModalState(false)}
      sx={styles.modal}
    >
      <Slide
        direction="up"
        in={modalState}
        mountOnEnter
        unmountOnExit
        timeout={500}
      >
        <Box
          sx={{
            ...styles.root,
            width: { xs: '85%', md: pxTovW(744) },
            maxHeight: { xs: '90vh', md: '100vh' },
          }}
        >
          <Box sx={styles.iconBox}>
            <IconWrapper
              name="wrong"
              size="md"
              parentFolder="icons"
              type="png"
              customSx={styles.managerIcon}
            />
          </Box>

          <ImageWrapper
            name="close"
            type="png"
            parentFolder="icons"
            styles={styles.close}
            onClick={() => closeModalState(false)}
          />

          <Box
            sx={{
              ...styles.headingText,
              mb: { xs: pxToRem(30), md: pxTovW(10) },
            }}
          >
            <Typography variant="h2" textAlign="center">
              {title
                ? title
                : isEditable
                ? 'Reject User Profile Verification'
                : 'Your verification request was rejected.'}
            </Typography>
          </Box>

          {isEditable ? (
            <>
              <Typography variant="h2">Choose Reason</Typography>
              {/* UI Issue if high number of reasons*/}
              <Box sx={styles.radioBox}>
                {reasonChoices?.map((choice, index) => (
                  <Box key={index} sx={styles.radioDetails}>
                    <Radio sx={styles.radio} {...controlRadioProps(choice)} />
                    <Typography
                      variant={isMobile ? 'smallText' : 'bodyText'}
                      fontWeight="regular"
                    >
                      {choice}
                    </Typography>
                  </Box>
                ))}
              </Box>

              <Box
                sx={{
                  mb: { xs: pxToRem(25), md: pxTovW(25) },
                }}
              >
                <InstructionsPanel
                  isEditable
                  title="Notes:"
                  value={notesValue}
                  onChange={(e: string | undefined) => {
                    setNotesValue && setNotesValue(e);
                  }}
                  customSx={styles.instructionsPanel}
                />
              </Box>
            </>
          ) : (
            <Box>
              <Box sx={styles.reasonContainer}>
                <Typography variant="h2">Reason:</Typography>
                <Typography variant="h2" fontWeight="light" fontFamily="Lato">
                  {rejectionReason ===
                  ProfileRejectionEnum.PROFILE_REJECTION_INCORRECT_DATA
                    ? 'Incorrect Details'
                    : rejectionReason ===
                      ProfileRejectionEnum.PROFILE_REJECTION_NOT_VALID_USER
                    ? 'Not a Valid User'
                    : 'Reason not Provided'}
                </Typography>
              </Box>

              <Box
                sx={{
                  ...styles.notesListBox,
                  maxHeight: { xs: '15vh', md: '25vh' },
                }}
              >
                {/* <Typography variant="h2">Notes:</Typography> */}

                <InstructionsPanel
                  customSx={{
                    display: 'flex',
                    flexGrow: 1,
                  }}
                  // customSx={styles.instructionsPanel}
                  title="Notes:"
                  // Katexvalue={TextEditorValue?.toString() || '<p></p>'}
                  Katexvalue={reasonData || '<p></p>'}
                />
              </Box>
            </Box>
          )}

          {isEditable ? (
            <Box sx={styles.checkProfileButtonBox}>
              <PrimaryButton fullWidth onClick={profileActionHandler}>
                <Typography variant="h3" color="common.white" fontWeight="bold">
                  REJECT
                </Typography>
              </PrimaryButton>
            </Box>
          ) : (
            <></>
          )}
        </Box>
      </Slide>
    </Modal>
  );
};
