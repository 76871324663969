import React, { useEffect, useState } from 'react';
import { Box, Typography } from '@mui/material';
import { pxToRem, pxTovW } from '../../../../src/commonUtils/resizeUtils';
interface TimerProps {
  initialTime: number;
  onTimeUpdate?: (newTime: number) => void; // Callback function to update time
  onTestEnd?: () => void; // Callback to handle end of the test
  isPaused?: boolean;
}
export const HomeworkTimer = (props: TimerProps) => {
  const { initialTime, onTimeUpdate, onTestEnd, isPaused } = props;
  const [timeRemaining, setTimeRemaining] = React.useState(initialTime);

  // useEffect(() => {
  //   // Check if initialTime is greater than 0 to start the timer
  //   if (timeRemaining <= 0) return;

  //   // const interval = setInterval(() => {
  //   //   setTimeRemaining((prevTime) => {
  //   //     if (prevTime <= 0) {
  //   //       clearInterval(interval);
  //   //       return 0;
  //   //     }
  //   //     return prevTime - 1;
  //   //   });
  //   // }, 1000);
  //   props.onTimeUpdate(timeRemaining - 1);

  //   // Cleanup interval when component unmounts
  //   // return () => clearInterval(interval);
  // }, [timeRemaining, props.onTimeUpdate]);

  useEffect(() => {
    if (timeRemaining < 0 || isPaused) return; // Don't run the timer if it's paused or time is up

    const interval = setInterval(() => {
      setTimeRemaining((prevTime) => {
        const newTime = prevTime - 1;
        // Call the onTimeUpdate callback every second
        onTimeUpdate && onTimeUpdate(newTime); // Calculate elapsed time and pass it to the callback

        if (newTime < 0) {
          clearInterval(interval); // Stop the timer when time runs out
          onTestEnd && onTestEnd(); // Notify the parent that the test has ended
        }

        return newTime;
      });
    }, 1000);

    // Cleanup the interval when the component unmounts or timeRemaining reaches 0
    return () => clearInterval(interval);
  }, [initialTime, onTimeUpdate, onTestEnd]);

  // Format time as MM:SS
  const minutes =
    timeRemaining >= 0
      ? Math.floor(timeRemaining / 60)
          .toString()
          .padStart(2, '0')
      : '00';
  const seconds =
    timeRemaining >= 0
      ? (timeRemaining % 60).toString().padStart(2, '0')
      : '00';
  return (
    <Box
      sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          width: { xs: pxToRem(144), md: pxTovW(340) },
          height: { xs: pxToRem(53), md: pxTovW(120) },
          position: 'relative',
          border: '2px solid transparent',
          borderRadius: '8px',
          boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
        }}
      >
        <Typography
          variant="h1"
          sx={{
            fontWeight: 'bold',
            color: '#000',
            textAlign: 'center',
          }}
        >
          {minutes}:{seconds}
        </Typography>

        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            gap: `${pxToRem(10)}`,
            marginTop: '4px',
          }}
        >
          <Typography
            variant="h3"
            sx={{
              fontWeight: 'bold',
              color: '#000',
              textAlign: 'center',
            }}
          >
            mins
          </Typography>
          <Typography
            variant="h3"
            sx={{
              fontWeight: 'bold',
              color: '#000',
              textAlign: 'center',
            }}
          >
            sec
          </Typography>
        </Box>
      </Box>

      <Typography
        variant="subtitle2"
        sx={{
          fontWeight: 'bold',
          marginTop: '12px',
          color: '#000',
        }}
      >
        Time Remaining
      </Typography>
    </Box>
  );
};
