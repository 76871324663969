import {
  DeviceEnum,
  ProfileRolesEnum,
} from '@protos/user_management/ums.db_pb';
import { FirebaseApp, initializeApp } from 'firebase/app';
import {
  Messaging,
  getMessaging,
  getToken,
  isSupported,
} from 'firebase/messaging';
import { useEffect, useState } from 'react';
import { useCommonServiceClientContext } from './CommonServiceClientProvider';
import { getEnvConfig } from './config';
import { getLocalStorage, setLocalStorage } from './utilFunctions';
export const useNotificationPermission = (profileType: string) => {
  const [permissionGranted, setPermissionGranted] = useState<boolean>(false);
  const [isMessagingSupported, setIsMessagingSupported] = useState<
    boolean | null
  >(null);
  const { UmsUtilityAPIServiceV1ClientWithStatusCodeHandler } =
    useCommonServiceClientContext();

  const config = getEnvConfig();
  const app: FirebaseApp = initializeApp(config.firebaseConfig);

  const topic = getLocalStorage('userId');
  const role = getLocalStorage('role');
  const roles =
    profileType === 'teacher'
      ? ProfileRolesEnum.PROFILE_ROLE_TEACHER
      : ProfileRolesEnum.PROFILE_ROLE_STUDENT;
  const deviceId = getLocalStorage('deviceID');
  const subscribeTopic = true;

  useEffect(() => {
    const initializeFirebaseMessaging = async (): Promise<void> => {
      try {
        const supported = await isSupported();
        setIsMessagingSupported(supported);
      } catch (error) {
        console.log('Error checking Firebase Messaging support:', error);
        setIsMessagingSupported(false);
      }
    };

    initializeFirebaseMessaging();
  }, []);

  const requestPermission = async (): Promise<void> => {
    if (!isMessagingSupported) {
      console.log(
        'Firebase Messaging is not supported. Skipping permission request.'
      );
      return;
    }
    try {
      const messaging: Messaging = getMessaging(app);
      const permission = await window.Notification.requestPermission();
      if (permission === 'granted') {
        setPermissionGranted(true);
        const currentToken = await getToken(messaging, {
          vapidKey: config.firebaseVapidKey,
        });
        const token = getLocalStorage('fcmToken');
        console.log('Stored Token:', token);
        if (currentToken !== String(token)) {
          console.log('Token:', currentToken);
          setLocalStorage('fcmToken', currentToken);
          if (currentToken) {
            const userId = getLocalStorage('userId');
            console.log('userId:', userId);
            if (userId) {
              await fetchFirebaseAPI(userId, currentToken);
              await subscribeFirebaseTopicAPI(
                userId,
                currentToken,
                subscribeTopic
              );
            } else {
              console.log('User ID not found');
            }
          } else {
            console.log('No registration token available');
          }
        } else {
          console.log('FCM token has not changed');
        }
      } else {
        console.log('Unable to get permission to notify');
      }
    } catch (error) {
      console.log('Error requesting permission:', error);
    }
  };

  const fetchFirebaseAPI = async (
    userId: string,
    fcmtoken: string
  ): Promise<void> => {
    if (!userId) return;

    try {
      const deviceInfo = {
        profileId: BigInt(userId),
        role: roles,
        deviceType: DeviceEnum.DEVICE_TYPE_WEB,
        deviceToken: fcmtoken,
        isActive: true,
        deviceId: deviceId ? BigInt(deviceId) : undefined,
      };

      const response =
        await UmsUtilityAPIServiceV1ClientWithStatusCodeHandler.saveDeviceInfo(
          deviceInfo
        );
      console.log('Firebase Token Response:', response);
      if (response && response.data && response.data.deviceId) {
        setLocalStorage('deviceID', Number(response.data.deviceId));
      }
    } catch (err) {
      console.log('Error:', err);
    }
  };

  const subscribeFirebaseTopicAPI = async (
    userId: string,
    fcmtoken: string,
    subscribeTopic: boolean
  ): Promise<void> => {
    if (!userId) return;

    try {
      const response =
        await UmsUtilityAPIServiceV1ClientWithStatusCodeHandler.saveDeviceInfo({
          deviceToken: fcmtoken,
          subscribeTopic: subscribeTopic,
          topic: `${userId}_${roles}`,
        });
    } catch (err) {
      console.log('Error:', err);
    }
  };

  return { permissionGranted, requestPermission };
};
