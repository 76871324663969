// @generated by protoc-gen-es v1.2.1 with parameter "target=ts"
// @generated from file src/protos/user_management/ums.common.apis.proto (package geneo.ums.common.apis, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import type { BinaryReadOptions, FieldList, JsonReadOptions, JsonValue, PartialMessage, PlainMessage } from "@bufbuild/protobuf";
import { Message, proto3, protoInt64, Timestamp } from "@bufbuild/protobuf";
import { DeviceDetails, DeviceEnum, Location, ProfileRolesEnum } from "@protos/user_management/ums.db_pb";

/**
 * @generated from message geneo.ums.common.apis.FetchCloseSessionRequest
 */
export class FetchCloseSessionRequest extends Message<FetchCloseSessionRequest> {
  constructor(data?: PartialMessage<FetchCloseSessionRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.ums.common.apis.FetchCloseSessionRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): FetchCloseSessionRequest {
    return new FetchCloseSessionRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): FetchCloseSessionRequest {
    return new FetchCloseSessionRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): FetchCloseSessionRequest {
    return new FetchCloseSessionRequest().fromJsonString(jsonString, options);
  }

  static equals(a: FetchCloseSessionRequest | PlainMessage<FetchCloseSessionRequest> | undefined, b: FetchCloseSessionRequest | PlainMessage<FetchCloseSessionRequest> | undefined): boolean {
    return proto3.util.equals(FetchCloseSessionRequest, a, b);
  }
}

/**
 * @generated from message geneo.ums.common.apis.FetchCloseSessionResponse
 */
export class FetchCloseSessionResponse extends Message<FetchCloseSessionResponse> {
  /**
   * @generated from field: int32 status = 1;
   */
  status = 0;

  /**
   * @generated from field: string code = 2;
   */
  code = "";

  /**
   * @generated from field: string message = 3;
   */
  message = "";

  constructor(data?: PartialMessage<FetchCloseSessionResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.ums.common.apis.FetchCloseSessionResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "status", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 2, name: "code", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "message", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): FetchCloseSessionResponse {
    return new FetchCloseSessionResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): FetchCloseSessionResponse {
    return new FetchCloseSessionResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): FetchCloseSessionResponse {
    return new FetchCloseSessionResponse().fromJsonString(jsonString, options);
  }

  static equals(a: FetchCloseSessionResponse | PlainMessage<FetchCloseSessionResponse> | undefined, b: FetchCloseSessionResponse | PlainMessage<FetchCloseSessionResponse> | undefined): boolean {
    return proto3.util.equals(FetchCloseSessionResponse, a, b);
  }
}

/**
 * @generated from message geneo.ums.common.apis.ContentTokenRequest
 */
export class ContentTokenRequest extends Message<ContentTokenRequest> {
  constructor(data?: PartialMessage<ContentTokenRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.ums.common.apis.ContentTokenRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ContentTokenRequest {
    return new ContentTokenRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ContentTokenRequest {
    return new ContentTokenRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ContentTokenRequest {
    return new ContentTokenRequest().fromJsonString(jsonString, options);
  }

  static equals(a: ContentTokenRequest | PlainMessage<ContentTokenRequest> | undefined, b: ContentTokenRequest | PlainMessage<ContentTokenRequest> | undefined): boolean {
    return proto3.util.equals(ContentTokenRequest, a, b);
  }
}

/**
 * @generated from message geneo.ums.common.apis.ContentTokenAuthRepsonse
 */
export class ContentTokenAuthRepsonse extends Message<ContentTokenAuthRepsonse> {
  /**
   * @generated from field: string token = 1;
   */
  token = "";

  constructor(data?: PartialMessage<ContentTokenAuthRepsonse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.ums.common.apis.ContentTokenAuthRepsonse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "token", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ContentTokenAuthRepsonse {
    return new ContentTokenAuthRepsonse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ContentTokenAuthRepsonse {
    return new ContentTokenAuthRepsonse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ContentTokenAuthRepsonse {
    return new ContentTokenAuthRepsonse().fromJsonString(jsonString, options);
  }

  static equals(a: ContentTokenAuthRepsonse | PlainMessage<ContentTokenAuthRepsonse> | undefined, b: ContentTokenAuthRepsonse | PlainMessage<ContentTokenAuthRepsonse> | undefined): boolean {
    return proto3.util.equals(ContentTokenAuthRepsonse, a, b);
  }
}

/**
 * @generated from message geneo.ums.common.apis.ContentTokenResponse
 */
export class ContentTokenResponse extends Message<ContentTokenResponse> {
  /**
   * @generated from field: int32 status = 1;
   */
  status = 0;

  /**
   * @generated from field: string code = 2;
   */
  code = "";

  /**
   * @generated from field: string message = 3;
   */
  message = "";

  /**
   * @generated from field: geneo.ums.common.apis.ContentTokenAuthRepsonse data = 4;
   */
  data?: ContentTokenAuthRepsonse;

  constructor(data?: PartialMessage<ContentTokenResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.ums.common.apis.ContentTokenResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "status", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 2, name: "code", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "message", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "data", kind: "message", T: ContentTokenAuthRepsonse },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ContentTokenResponse {
    return new ContentTokenResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ContentTokenResponse {
    return new ContentTokenResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ContentTokenResponse {
    return new ContentTokenResponse().fromJsonString(jsonString, options);
  }

  static equals(a: ContentTokenResponse | PlainMessage<ContentTokenResponse> | undefined, b: ContentTokenResponse | PlainMessage<ContentTokenResponse> | undefined): boolean {
    return proto3.util.equals(ContentTokenResponse, a, b);
  }
}

/**
 * @generated from message geneo.ums.common.apis.GetStudentNotificationSettingsRequest
 */
export class GetStudentNotificationSettingsRequest extends Message<GetStudentNotificationSettingsRequest> {
  /**
   * @generated from field: int64 student_id = 1;
   */
  studentId = protoInt64.zero;

  constructor(data?: PartialMessage<GetStudentNotificationSettingsRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.ums.common.apis.GetStudentNotificationSettingsRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "student_id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetStudentNotificationSettingsRequest {
    return new GetStudentNotificationSettingsRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetStudentNotificationSettingsRequest {
    return new GetStudentNotificationSettingsRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetStudentNotificationSettingsRequest {
    return new GetStudentNotificationSettingsRequest().fromJsonString(jsonString, options);
  }

  static equals(a: GetStudentNotificationSettingsRequest | PlainMessage<GetStudentNotificationSettingsRequest> | undefined, b: GetStudentNotificationSettingsRequest | PlainMessage<GetStudentNotificationSettingsRequest> | undefined): boolean {
    return proto3.util.equals(GetStudentNotificationSettingsRequest, a, b);
  }
}

/**
 * @generated from message geneo.ums.common.apis.NotificationSettings
 */
export class NotificationSettings extends Message<NotificationSettings> {
  /**
   * @generated from field: bool enable_notifications = 1;
   */
  enableNotifications = false;

  /**
   * @generated from field: string enable_push_notifications = 5;
   */
  enablePushNotifications = "";

  /**
   * @generated from field: string enable_emails = 2;
   */
  enableEmails = "";

  /**
   * @generated from field: string enable_sms = 3;
   */
  enableSms = "";

  /**
   * @generated from field: string enable_new_content_notifications = 4;
   */
  enableNewContentNotifications = "";

  constructor(data?: PartialMessage<NotificationSettings>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.ums.common.apis.NotificationSettings";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "enable_notifications", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 5, name: "enable_push_notifications", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "enable_emails", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "enable_sms", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "enable_new_content_notifications", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): NotificationSettings {
    return new NotificationSettings().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): NotificationSettings {
    return new NotificationSettings().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): NotificationSettings {
    return new NotificationSettings().fromJsonString(jsonString, options);
  }

  static equals(a: NotificationSettings | PlainMessage<NotificationSettings> | undefined, b: NotificationSettings | PlainMessage<NotificationSettings> | undefined): boolean {
    return proto3.util.equals(NotificationSettings, a, b);
  }
}

/**
 * @generated from message geneo.ums.common.apis.GetStudentNotificationSettingsResponse
 */
export class GetStudentNotificationSettingsResponse extends Message<GetStudentNotificationSettingsResponse> {
  /**
   * @generated from field: geneo.ums.common.apis.NotificationSettings notification_settings = 1;
   */
  notificationSettings?: NotificationSettings;

  constructor(data?: PartialMessage<GetStudentNotificationSettingsResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.ums.common.apis.GetStudentNotificationSettingsResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "notification_settings", kind: "message", T: NotificationSettings },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetStudentNotificationSettingsResponse {
    return new GetStudentNotificationSettingsResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetStudentNotificationSettingsResponse {
    return new GetStudentNotificationSettingsResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetStudentNotificationSettingsResponse {
    return new GetStudentNotificationSettingsResponse().fromJsonString(jsonString, options);
  }

  static equals(a: GetStudentNotificationSettingsResponse | PlainMessage<GetStudentNotificationSettingsResponse> | undefined, b: GetStudentNotificationSettingsResponse | PlainMessage<GetStudentNotificationSettingsResponse> | undefined): boolean {
    return proto3.util.equals(GetStudentNotificationSettingsResponse, a, b);
  }
}

/**
 * @generated from message geneo.ums.common.apis.GetStudentSupportRequest
 */
export class GetStudentSupportRequest extends Message<GetStudentSupportRequest> {
  /**
   * @generated from field: int64 student_id = 1;
   */
  studentId = protoInt64.zero;

  constructor(data?: PartialMessage<GetStudentSupportRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.ums.common.apis.GetStudentSupportRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "student_id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetStudentSupportRequest {
    return new GetStudentSupportRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetStudentSupportRequest {
    return new GetStudentSupportRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetStudentSupportRequest {
    return new GetStudentSupportRequest().fromJsonString(jsonString, options);
  }

  static equals(a: GetStudentSupportRequest | PlainMessage<GetStudentSupportRequest> | undefined, b: GetStudentSupportRequest | PlainMessage<GetStudentSupportRequest> | undefined): boolean {
    return proto3.util.equals(GetStudentSupportRequest, a, b);
  }
}

/**
 * @generated from message geneo.ums.common.apis.SupportInfo
 */
export class SupportInfo extends Message<SupportInfo> {
  /**
   * @generated from field: geneo.ums.common.apis.SupportInfo.ContactUs contact_us = 1;
   */
  contactUs?: SupportInfo_ContactUs;

  /**
   * @generated from field: repeated geneo.ums.common.apis.SupportInfo.Faq faq = 3;
   */
  faq: SupportInfo_Faq[] = [];

  /**
   * @generated from field: repeated geneo.ums.common.apis.SupportInfo.PrivacyPolicy privacy_policy = 2;
   */
  privacyPolicy: SupportInfo_PrivacyPolicy[] = [];

  constructor(data?: PartialMessage<SupportInfo>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.ums.common.apis.SupportInfo";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "contact_us", kind: "message", T: SupportInfo_ContactUs },
    { no: 3, name: "faq", kind: "message", T: SupportInfo_Faq, repeated: true },
    { no: 2, name: "privacy_policy", kind: "message", T: SupportInfo_PrivacyPolicy, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): SupportInfo {
    return new SupportInfo().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): SupportInfo {
    return new SupportInfo().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): SupportInfo {
    return new SupportInfo().fromJsonString(jsonString, options);
  }

  static equals(a: SupportInfo | PlainMessage<SupportInfo> | undefined, b: SupportInfo | PlainMessage<SupportInfo> | undefined): boolean {
    return proto3.util.equals(SupportInfo, a, b);
  }
}

/**
 * @generated from message geneo.ums.common.apis.SupportInfo.Faq
 */
export class SupportInfo_Faq extends Message<SupportInfo_Faq> {
  /**
   * @generated from field: string question = 1;
   */
  question = "";

  /**
   * @generated from field: string response = 2;
   */
  response = "";

  constructor(data?: PartialMessage<SupportInfo_Faq>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.ums.common.apis.SupportInfo.Faq";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "question", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "response", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): SupportInfo_Faq {
    return new SupportInfo_Faq().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): SupportInfo_Faq {
    return new SupportInfo_Faq().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): SupportInfo_Faq {
    return new SupportInfo_Faq().fromJsonString(jsonString, options);
  }

  static equals(a: SupportInfo_Faq | PlainMessage<SupportInfo_Faq> | undefined, b: SupportInfo_Faq | PlainMessage<SupportInfo_Faq> | undefined): boolean {
    return proto3.util.equals(SupportInfo_Faq, a, b);
  }
}

/**
 * @generated from message geneo.ums.common.apis.SupportInfo.ContactUs
 */
export class SupportInfo_ContactUs extends Message<SupportInfo_ContactUs> {
  /**
   * @generated from field: string phone = 1;
   */
  phone = "";

  /**
   * @generated from field: string email = 2;
   */
  email = "";

  constructor(data?: PartialMessage<SupportInfo_ContactUs>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.ums.common.apis.SupportInfo.ContactUs";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "phone", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "email", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): SupportInfo_ContactUs {
    return new SupportInfo_ContactUs().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): SupportInfo_ContactUs {
    return new SupportInfo_ContactUs().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): SupportInfo_ContactUs {
    return new SupportInfo_ContactUs().fromJsonString(jsonString, options);
  }

  static equals(a: SupportInfo_ContactUs | PlainMessage<SupportInfo_ContactUs> | undefined, b: SupportInfo_ContactUs | PlainMessage<SupportInfo_ContactUs> | undefined): boolean {
    return proto3.util.equals(SupportInfo_ContactUs, a, b);
  }
}

/**
 * @generated from message geneo.ums.common.apis.SupportInfo.PrivacyPolicy
 */
export class SupportInfo_PrivacyPolicy extends Message<SupportInfo_PrivacyPolicy> {
  /**
   * @generated from field: string policy = 1;
   */
  policy = "";

  /**
   * @generated from field: string description = 2;
   */
  description = "";

  constructor(data?: PartialMessage<SupportInfo_PrivacyPolicy>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.ums.common.apis.SupportInfo.PrivacyPolicy";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "policy", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "description", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): SupportInfo_PrivacyPolicy {
    return new SupportInfo_PrivacyPolicy().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): SupportInfo_PrivacyPolicy {
    return new SupportInfo_PrivacyPolicy().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): SupportInfo_PrivacyPolicy {
    return new SupportInfo_PrivacyPolicy().fromJsonString(jsonString, options);
  }

  static equals(a: SupportInfo_PrivacyPolicy | PlainMessage<SupportInfo_PrivacyPolicy> | undefined, b: SupportInfo_PrivacyPolicy | PlainMessage<SupportInfo_PrivacyPolicy> | undefined): boolean {
    return proto3.util.equals(SupportInfo_PrivacyPolicy, a, b);
  }
}

/**
 * @generated from message geneo.ums.common.apis.GetStudentSupportResponse
 */
export class GetStudentSupportResponse extends Message<GetStudentSupportResponse> {
  /**
   * @generated from field: geneo.ums.common.apis.SupportInfo support_info = 1;
   */
  supportInfo?: SupportInfo;

  constructor(data?: PartialMessage<GetStudentSupportResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.ums.common.apis.GetStudentSupportResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "support_info", kind: "message", T: SupportInfo },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetStudentSupportResponse {
    return new GetStudentSupportResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetStudentSupportResponse {
    return new GetStudentSupportResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetStudentSupportResponse {
    return new GetStudentSupportResponse().fromJsonString(jsonString, options);
  }

  static equals(a: GetStudentSupportResponse | PlainMessage<GetStudentSupportResponse> | undefined, b: GetStudentSupportResponse | PlainMessage<GetStudentSupportResponse> | undefined): boolean {
    return proto3.util.equals(GetStudentSupportResponse, a, b);
  }
}

/**
 * @generated from message geneo.ums.common.apis.DeviceInfoRequest
 */
export class DeviceInfoRequest extends Message<DeviceInfoRequest> {
  /**
   * Foreign key referencing profile.profile_id
   *
   * @generated from field: int64 profile_id = 1;
   */
  profileId = protoInt64.zero;

  /**
   * @generated from field: geneo.ums.db.ProfileRolesEnum role = 2;
   */
  role = ProfileRolesEnum.PROFILE_ROLE_UNDEFINED;

  /**
   * Type of the device
   *
   * @generated from field: geneo.ums.db.DeviceEnum device_type = 3;
   */
  deviceType = DeviceEnum.DEVICE_TYPE_UNDEFINED;

  /**
   * JSON object containing device details
   *
   * @generated from field: optional geneo.ums.db.DeviceDetails device_details = 4;
   */
  deviceDetails?: DeviceDetails;

  /**
   * Device token
   *
   * @generated from field: string device_token = 5;
   */
  deviceToken = "";

  /**
   * JSON object containing location details
   *
   * @generated from field: optional geneo.ums.db.Location location = 6;
   */
  location?: Location;

  /**
   * Creation timestamp
   *
   * @generated from field: google.protobuf.Timestamp created_on = 7;
   */
  createdOn?: Timestamp;

  /**
   * Last modification timestamp
   *
   * @generated from field: optional google.protobuf.Timestamp modified_on = 8;
   */
  modifiedOn?: Timestamp;

  /**
   * Last modification timestamp
   *
   * @generated from field: string topic = 9;
   */
  topic = "";

  /**
   * @generated from field: optional bool subscribe_topic = 10;
   */
  subscribeTopic?: boolean;

  /**
   * @generated from field: optional bool is_active = 11;
   */
  isActive?: boolean;

  /**
   * @generated from field: int64 device_id = 12;
   */
  deviceId = protoInt64.zero;

  constructor(data?: PartialMessage<DeviceInfoRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.ums.common.apis.DeviceInfoRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "profile_id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 2, name: "role", kind: "enum", T: proto3.getEnumType(ProfileRolesEnum) },
    { no: 3, name: "device_type", kind: "enum", T: proto3.getEnumType(DeviceEnum) },
    { no: 4, name: "device_details", kind: "message", T: DeviceDetails, opt: true },
    { no: 5, name: "device_token", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 6, name: "location", kind: "message", T: Location, opt: true },
    { no: 7, name: "created_on", kind: "message", T: Timestamp },
    { no: 8, name: "modified_on", kind: "message", T: Timestamp, opt: true },
    { no: 9, name: "topic", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 10, name: "subscribe_topic", kind: "scalar", T: 8 /* ScalarType.BOOL */, opt: true },
    { no: 11, name: "is_active", kind: "scalar", T: 8 /* ScalarType.BOOL */, opt: true },
    { no: 12, name: "device_id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): DeviceInfoRequest {
    return new DeviceInfoRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): DeviceInfoRequest {
    return new DeviceInfoRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): DeviceInfoRequest {
    return new DeviceInfoRequest().fromJsonString(jsonString, options);
  }

  static equals(a: DeviceInfoRequest | PlainMessage<DeviceInfoRequest> | undefined, b: DeviceInfoRequest | PlainMessage<DeviceInfoRequest> | undefined): boolean {
    return proto3.util.equals(DeviceInfoRequest, a, b);
  }
}

/**
 * @generated from message geneo.ums.common.apis.DeviceInfoData
 */
export class DeviceInfoData extends Message<DeviceInfoData> {
  /**
   * The token to access the content.
   *
   * @generated from field: int64 device_id = 1;
   */
  deviceId = protoInt64.zero;

  constructor(data?: PartialMessage<DeviceInfoData>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.ums.common.apis.DeviceInfoData";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "device_id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): DeviceInfoData {
    return new DeviceInfoData().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): DeviceInfoData {
    return new DeviceInfoData().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): DeviceInfoData {
    return new DeviceInfoData().fromJsonString(jsonString, options);
  }

  static equals(a: DeviceInfoData | PlainMessage<DeviceInfoData> | undefined, b: DeviceInfoData | PlainMessage<DeviceInfoData> | undefined): boolean {
    return proto3.util.equals(DeviceInfoData, a, b);
  }
}

/**
 * @generated from message geneo.ums.common.apis.DeviceInfoResponse
 */
export class DeviceInfoResponse extends Message<DeviceInfoResponse> {
  /**
   * @generated from field: int32 status = 1;
   */
  status = 0;

  /**
   * @generated from field: string code = 2;
   */
  code = "";

  /**
   * @generated from field: string message = 3;
   */
  message = "";

  /**
   * @generated from field: geneo.ums.common.apis.DeviceInfoData data = 4;
   */
  data?: DeviceInfoData;

  constructor(data?: PartialMessage<DeviceInfoResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.ums.common.apis.DeviceInfoResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "status", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 2, name: "code", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "message", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "data", kind: "message", T: DeviceInfoData },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): DeviceInfoResponse {
    return new DeviceInfoResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): DeviceInfoResponse {
    return new DeviceInfoResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): DeviceInfoResponse {
    return new DeviceInfoResponse().fromJsonString(jsonString, options);
  }

  static equals(a: DeviceInfoResponse | PlainMessage<DeviceInfoResponse> | undefined, b: DeviceInfoResponse | PlainMessage<DeviceInfoResponse> | undefined): boolean {
    return proto3.util.equals(DeviceInfoResponse, a, b);
  }
}

/**
 * @generated from message geneo.ums.common.apis.ActiveSessionData
 */
export class ActiveSessionData extends Message<ActiveSessionData> {
  /**
   * @generated from field: optional int32 active_session_id = 1;
   */
  activeSessionId?: number;

  /**
   * @generated from field: optional google.protobuf.Timestamp start_time = 2;
   */
  startTime?: Timestamp;

  /**
   * @generated from field: optional google.protobuf.Timestamp last_usage_time = 3;
   */
  lastUsageTime?: Timestamp;

  /**
   * @generated from field: optional google.protobuf.Timestamp end_time = 4;
   */
  endTime?: Timestamp;

  constructor(data?: PartialMessage<ActiveSessionData>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.ums.common.apis.ActiveSessionData";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "active_session_id", kind: "scalar", T: 5 /* ScalarType.INT32 */, opt: true },
    { no: 2, name: "start_time", kind: "message", T: Timestamp, opt: true },
    { no: 3, name: "last_usage_time", kind: "message", T: Timestamp, opt: true },
    { no: 4, name: "end_time", kind: "message", T: Timestamp, opt: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ActiveSessionData {
    return new ActiveSessionData().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ActiveSessionData {
    return new ActiveSessionData().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ActiveSessionData {
    return new ActiveSessionData().fromJsonString(jsonString, options);
  }

  static equals(a: ActiveSessionData | PlainMessage<ActiveSessionData> | undefined, b: ActiveSessionData | PlainMessage<ActiveSessionData> | undefined): boolean {
    return proto3.util.equals(ActiveSessionData, a, b);
  }
}

/**
 * @generated from message geneo.ums.common.apis.ActiveSessionDataError
 */
export class ActiveSessionDataError extends Message<ActiveSessionDataError> {
  /**
   * @generated from field: geneo.ums.common.apis.ActiveSessionData errors = 1;
   */
  errors?: ActiveSessionData;

  constructor(data?: PartialMessage<ActiveSessionDataError>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.ums.common.apis.ActiveSessionDataError";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "errors", kind: "message", T: ActiveSessionData },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ActiveSessionDataError {
    return new ActiveSessionDataError().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ActiveSessionDataError {
    return new ActiveSessionDataError().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ActiveSessionDataError {
    return new ActiveSessionDataError().fromJsonString(jsonString, options);
  }

  static equals(a: ActiveSessionDataError | PlainMessage<ActiveSessionDataError> | undefined, b: ActiveSessionDataError | PlainMessage<ActiveSessionDataError> | undefined): boolean {
    return proto3.util.equals(ActiveSessionDataError, a, b);
  }
}

/**
 * @generated from message geneo.ums.common.apis.ActiveSessionRequest
 */
export class ActiveSessionRequest extends Message<ActiveSessionRequest> {
  /**
   * @generated from field: optional int32 active_session_id = 1;
   */
  activeSessionId?: number;

  /**
   * @generated from field: optional google.protobuf.Timestamp start_time = 2;
   */
  startTime?: Timestamp;

  /**
   * @generated from field: optional google.protobuf.Timestamp last_usage_time = 3;
   */
  lastUsageTime?: Timestamp;

  /**
   * @generated from field: optional google.protobuf.Timestamp end_time = 4;
   */
  endTime?: Timestamp;

  /**
   * @generated from field: optional int64 profile_login_details_id = 5;
   */
  profileLoginDetailsId?: bigint;

  /**
   * @generated from field: optional int64 profile_id = 6;
   */
  profileId?: bigint;

  /**
   * @generated from field: geneo.ums.db.ProfileRolesEnum role = 7;
   */
  role = ProfileRolesEnum.PROFILE_ROLE_UNDEFINED;

  constructor(data?: PartialMessage<ActiveSessionRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.ums.common.apis.ActiveSessionRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "active_session_id", kind: "scalar", T: 5 /* ScalarType.INT32 */, opt: true },
    { no: 2, name: "start_time", kind: "message", T: Timestamp, opt: true },
    { no: 3, name: "last_usage_time", kind: "message", T: Timestamp, opt: true },
    { no: 4, name: "end_time", kind: "message", T: Timestamp, opt: true },
    { no: 5, name: "profile_login_details_id", kind: "scalar", T: 3 /* ScalarType.INT64 */, opt: true },
    { no: 6, name: "profile_id", kind: "scalar", T: 3 /* ScalarType.INT64 */, opt: true },
    { no: 7, name: "role", kind: "enum", T: proto3.getEnumType(ProfileRolesEnum) },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ActiveSessionRequest {
    return new ActiveSessionRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ActiveSessionRequest {
    return new ActiveSessionRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ActiveSessionRequest {
    return new ActiveSessionRequest().fromJsonString(jsonString, options);
  }

  static equals(a: ActiveSessionRequest | PlainMessage<ActiveSessionRequest> | undefined, b: ActiveSessionRequest | PlainMessage<ActiveSessionRequest> | undefined): boolean {
    return proto3.util.equals(ActiveSessionRequest, a, b);
  }
}

/**
 * @generated from message geneo.ums.common.apis.ActiveSessionResponse
 */
export class ActiveSessionResponse extends Message<ActiveSessionResponse> {
  /**
   * @generated from field: int32 status = 1;
   */
  status = 0;

  /**
   * @generated from field: string code = 2;
   */
  code = "";

  /**
   * @generated from field: string message = 3;
   */
  message = "";

  /**
   * @generated from oneof geneo.ums.common.apis.ActiveSessionResponse.data
   */
  data: {
    /**
     * @generated from field: geneo.ums.common.apis.ActiveSessionData activeSessionData = 4;
     */
    value: ActiveSessionData;
    case: "activeSessionData";
  } | {
    /**
     * @generated from field: geneo.ums.common.apis.ActiveSessionDataError activeSessionDataError = 5;
     */
    value: ActiveSessionDataError;
    case: "activeSessionDataError";
  } | { case: undefined; value?: undefined } = { case: undefined };

  constructor(data?: PartialMessage<ActiveSessionResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.ums.common.apis.ActiveSessionResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "status", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 2, name: "code", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "message", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "activeSessionData", kind: "message", T: ActiveSessionData, oneof: "data" },
    { no: 5, name: "activeSessionDataError", kind: "message", T: ActiveSessionDataError, oneof: "data" },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ActiveSessionResponse {
    return new ActiveSessionResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ActiveSessionResponse {
    return new ActiveSessionResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ActiveSessionResponse {
    return new ActiveSessionResponse().fromJsonString(jsonString, options);
  }

  static equals(a: ActiveSessionResponse | PlainMessage<ActiveSessionResponse> | undefined, b: ActiveSessionResponse | PlainMessage<ActiveSessionResponse> | undefined): boolean {
    return proto3.util.equals(ActiveSessionResponse, a, b);
  }
}

/**
 * @generated from message geneo.ums.common.apis.ValidateLoginSessionRequest
 */
export class ValidateLoginSessionRequest extends Message<ValidateLoginSessionRequest> {
  /**
   * @generated from field: string user_name = 1;
   */
  userName = "";

  /**
   * @generated from field: geneo.ums.db.ProfileRolesEnum role = 2;
   */
  role = ProfileRolesEnum.PROFILE_ROLE_UNDEFINED;

  /**
   * @generated from field: string verification_code = 3;
   */
  verificationCode = "";

  constructor(data?: PartialMessage<ValidateLoginSessionRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.ums.common.apis.ValidateLoginSessionRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "user_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "role", kind: "enum", T: proto3.getEnumType(ProfileRolesEnum) },
    { no: 3, name: "verification_code", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ValidateLoginSessionRequest {
    return new ValidateLoginSessionRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ValidateLoginSessionRequest {
    return new ValidateLoginSessionRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ValidateLoginSessionRequest {
    return new ValidateLoginSessionRequest().fromJsonString(jsonString, options);
  }

  static equals(a: ValidateLoginSessionRequest | PlainMessage<ValidateLoginSessionRequest> | undefined, b: ValidateLoginSessionRequest | PlainMessage<ValidateLoginSessionRequest> | undefined): boolean {
    return proto3.util.equals(ValidateLoginSessionRequest, a, b);
  }
}

/**
 * @generated from message geneo.ums.common.apis.ValidateLoginSessionResponse
 */
export class ValidateLoginSessionResponse extends Message<ValidateLoginSessionResponse> {
  /**
   * @generated from field: int32 status = 1;
   */
  status = 0;

  /**
   * @generated from field: string code = 2;
   */
  code = "";

  /**
   * @generated from field: string message = 3;
   */
  message = "";

  /**
   * @generated from field: string token = 4;
   */
  token = "";

  /**
   * @generated from field: int32 active_session_id = 5;
   */
  activeSessionId = 0;

  /**
   * @generated from field: int64 profile_login_details_id = 6;
   */
  profileLoginDetailsId = protoInt64.zero;

  constructor(data?: PartialMessage<ValidateLoginSessionResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.ums.common.apis.ValidateLoginSessionResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "status", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 2, name: "code", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "message", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "token", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "active_session_id", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 6, name: "profile_login_details_id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ValidateLoginSessionResponse {
    return new ValidateLoginSessionResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ValidateLoginSessionResponse {
    return new ValidateLoginSessionResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ValidateLoginSessionResponse {
    return new ValidateLoginSessionResponse().fromJsonString(jsonString, options);
  }

  static equals(a: ValidateLoginSessionResponse | PlainMessage<ValidateLoginSessionResponse> | undefined, b: ValidateLoginSessionResponse | PlainMessage<ValidateLoginSessionResponse> | undefined): boolean {
    return proto3.util.equals(ValidateLoginSessionResponse, a, b);
  }
}

/**
 * @generated from message geneo.ums.common.apis.RefreshUserAccessTokenRequest
 */
export class RefreshUserAccessTokenRequest extends Message<RefreshUserAccessTokenRequest> {
  /**
   * @generated from field: int64 profile_id = 1;
   */
  profileId = protoInt64.zero;

  /**
   * @generated from field: geneo.ums.db.ProfileRolesEnum role = 2;
   */
  role = ProfileRolesEnum.PROFILE_ROLE_UNDEFINED;

  constructor(data?: PartialMessage<RefreshUserAccessTokenRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.ums.common.apis.RefreshUserAccessTokenRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "profile_id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 2, name: "role", kind: "enum", T: proto3.getEnumType(ProfileRolesEnum) },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): RefreshUserAccessTokenRequest {
    return new RefreshUserAccessTokenRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): RefreshUserAccessTokenRequest {
    return new RefreshUserAccessTokenRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): RefreshUserAccessTokenRequest {
    return new RefreshUserAccessTokenRequest().fromJsonString(jsonString, options);
  }

  static equals(a: RefreshUserAccessTokenRequest | PlainMessage<RefreshUserAccessTokenRequest> | undefined, b: RefreshUserAccessTokenRequest | PlainMessage<RefreshUserAccessTokenRequest> | undefined): boolean {
    return proto3.util.equals(RefreshUserAccessTokenRequest, a, b);
  }
}

/**
 * @generated from message geneo.ums.common.apis.RefreshUserAccessTokenResponse
 */
export class RefreshUserAccessTokenResponse extends Message<RefreshUserAccessTokenResponse> {
  /**
   * @generated from field: int32 status = 1;
   */
  status = 0;

  /**
   * @generated from field: string code = 2;
   */
  code = "";

  /**
   * @generated from field: string message = 3;
   */
  message = "";

  /**
   * @generated from field: string token = 4;
   */
  token = "";

  constructor(data?: PartialMessage<RefreshUserAccessTokenResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.ums.common.apis.RefreshUserAccessTokenResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "status", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 2, name: "code", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "message", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "token", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): RefreshUserAccessTokenResponse {
    return new RefreshUserAccessTokenResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): RefreshUserAccessTokenResponse {
    return new RefreshUserAccessTokenResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): RefreshUserAccessTokenResponse {
    return new RefreshUserAccessTokenResponse().fromJsonString(jsonString, options);
  }

  static equals(a: RefreshUserAccessTokenResponse | PlainMessage<RefreshUserAccessTokenResponse> | undefined, b: RefreshUserAccessTokenResponse | PlainMessage<RefreshUserAccessTokenResponse> | undefined): boolean {
    return proto3.util.equals(RefreshUserAccessTokenResponse, a, b);
  }
}

/**
 * @generated from message geneo.ums.common.apis.GenerateCustomTokenWithExpiryRequest
 */
export class GenerateCustomTokenWithExpiryRequest extends Message<GenerateCustomTokenWithExpiryRequest> {
  /**
   * @generated from field: int64 profile_id = 1;
   */
  profileId = protoInt64.zero;

  /**
   * @generated from field: geneo.ums.db.ProfileRolesEnum role = 2;
   */
  role = ProfileRolesEnum.PROFILE_ROLE_UNDEFINED;

  /**
   * @generated from field: string token_expires_in = 3;
   */
  tokenExpiresIn = "";

  constructor(data?: PartialMessage<GenerateCustomTokenWithExpiryRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.ums.common.apis.GenerateCustomTokenWithExpiryRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "profile_id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 2, name: "role", kind: "enum", T: proto3.getEnumType(ProfileRolesEnum) },
    { no: 3, name: "token_expires_in", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GenerateCustomTokenWithExpiryRequest {
    return new GenerateCustomTokenWithExpiryRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GenerateCustomTokenWithExpiryRequest {
    return new GenerateCustomTokenWithExpiryRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GenerateCustomTokenWithExpiryRequest {
    return new GenerateCustomTokenWithExpiryRequest().fromJsonString(jsonString, options);
  }

  static equals(a: GenerateCustomTokenWithExpiryRequest | PlainMessage<GenerateCustomTokenWithExpiryRequest> | undefined, b: GenerateCustomTokenWithExpiryRequest | PlainMessage<GenerateCustomTokenWithExpiryRequest> | undefined): boolean {
    return proto3.util.equals(GenerateCustomTokenWithExpiryRequest, a, b);
  }
}

/**
 * @generated from message geneo.ums.common.apis.GenerateCustomTokenWithExpiryResponse
 */
export class GenerateCustomTokenWithExpiryResponse extends Message<GenerateCustomTokenWithExpiryResponse> {
  /**
   * @generated from field: int32 status = 1;
   */
  status = 0;

  /**
   * @generated from field: string code = 2;
   */
  code = "";

  /**
   * @generated from field: string message = 3;
   */
  message = "";

  /**
   * @generated from field: string token = 4;
   */
  token = "";

  constructor(data?: PartialMessage<GenerateCustomTokenWithExpiryResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.ums.common.apis.GenerateCustomTokenWithExpiryResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "status", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 2, name: "code", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "message", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "token", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GenerateCustomTokenWithExpiryResponse {
    return new GenerateCustomTokenWithExpiryResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GenerateCustomTokenWithExpiryResponse {
    return new GenerateCustomTokenWithExpiryResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GenerateCustomTokenWithExpiryResponse {
    return new GenerateCustomTokenWithExpiryResponse().fromJsonString(jsonString, options);
  }

  static equals(a: GenerateCustomTokenWithExpiryResponse | PlainMessage<GenerateCustomTokenWithExpiryResponse> | undefined, b: GenerateCustomTokenWithExpiryResponse | PlainMessage<GenerateCustomTokenWithExpiryResponse> | undefined): boolean {
    return proto3.util.equals(GenerateCustomTokenWithExpiryResponse, a, b);
  }
}

